import { FC, ImgHTMLAttributes } from 'react';
import clsx from 'classnames';
import styles from './ie-icon.module.scss';

type IconProps = {
    src: string;
    alt: string;
    size?: 'medium' | 'small' | 'large' | 'xlarge';
    className?: string;
} & ImgHTMLAttributes<HTMLImageElement>;

export const IeIcon: FC<IconProps> = ({ src, alt, className, size = 'medium', ...props }) => {
    return (
        <div
            className={clsx({
                [styles.icon]: true,
                [String(className)]: className,
                [styles[size]]: true,
            })}
            {...props}
        >
            <img src={src} alt={alt} />
        </div>
    );
};
