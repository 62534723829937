import { useCallback, useEffect, useState, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'classnames';
import {
    TwoLevelMenu,
    TwoLevelMenuOther,
    TwoLevelMenuOtherProfile,
    TwoLevelMenuSubscriptions,
} from './two-level-menu/two-level-menu';
import { extraining, portfolio, products, profile } from '@utils/two-level-menu-data';
import { useActiveButtonCountryTagChoice } from '@services/context/country-filter-context';
import { setAuthChecked } from '@services/reducers/user-slice';
import { useTheme } from '@services/hooks/use-theme';
import SearchInput from './input-search/input-search';
import { ymHit } from '@utils/ym-hit.js';
import InvestEraWideLogo from '@images/layout/ie-logo.svg';
import InvestEraSmallLogo from '@images/layout/logo-logo.svg';
import ActiveSunSvg from '@images/layout/active-sun-icon.svg';
import ActiveMoonSvg from '@images/layout/active-moon-icon.svg';
import SearchLogo from '@images/layout/Serch.svg';
import ArrowDown from '@images/layout/arrow-down.svg';
import ChevronDown from '@images/arrows/Chevron--down.svg';
import ArrowUp from '@images/layout/arrow-up.svg';
import Close from '@images/layout/close-x-sign-svg.svg';
import UserLogo from '@images/layout/user.svg';
import { HeaderLink } from '@components/app/layout-main/app-header-main/header-link/header-link';
import { HeaderDropdown } from '@components/app/layout-main/app-header-main/header-dropdown/header-dropdown';
import DoorExit from '@images/log-out-door-profile-icon.svg';
import DoorJoin from '@images/door-exit-dark.svg';
import StockAllPages from '@components/app/stock-all-pages/stock-all-pages';
import styles from './app-header-main.module.scss';

function AppHeaderMain() {
    const isAuthChecked = useSelector((store) => store.user.isAuthChecked);
    const value = useActiveButtonCountryTagChoice();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const headerRef = useRef(null);
    const { theme, toggleTheme } = useTheme();
    const [openSearch, setOpenSearch] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [isOpenMenuHoveredTraining, setIsOverMenuHoveredTraining] = useState(false);
    const [isOpenMenuHoveredPortfolio, setIsOverMenuHoveredPortfolio] = useState(false);
    const [isOpenMenuHoveredProfile, setIsOverMenuHoveredProfile] = useState(false);
    const [isOpenMenuHoveredSubscriptions, setIsOverMenuHoveredSubscriptions] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState({
        portfolio: false,
        subscriptions: false,
        products: false,
        education: false,
    });

    const token = localStorage.getItem('token');

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
        if (!openMenu) {
            onClickHandlerYM('/menu-open');
            document.body.style.overflow = 'hidden';
        } else {
            onClickHandlerYM('/menu-close');
            document.body.style.overflow = '';
        }
    };

    const navigateProfile = useCallback(() => {
        onClickHandlerYM('/entry');
        if (!token) {
            navigate('/authorization');
        } else {
            navigate('/profile');
        }
    }, []);

    const logout = useCallback(() => {
        onClickHandlerYM('/quit');
        value.setPopUpExitVision((prev) => !prev);
    }, []);

    useEffect(() => {
        // Проверяем, есть ли токен в localStorage
        const token = localStorage.getItem('token');
        // Если токен есть, то isAuthChecked должно быть true
        if (token) {
            dispatch(setAuthChecked(true));
        } else {
            // Если токен нет, то isAuthChecked должно быть false
            dispatch(setAuthChecked(false));
        }
    }, [isAuthChecked]);

    const closeAllToggleMenu = () => {
        setIsOverMenuHoveredSubscriptions(false);
        setIsOverMenuHoveredTraining(false);
        setIsOverMenuHoveredPortfolio(false);
        setIsOverMenuHoveredProfile(false);
    };

    const toggleSubMenu = (type) => {
        setOpenSubMenu((prevState) => ({
            ...prevState,
            [type]: !prevState[type],
        }));
    };

    const toggleOpenCloseMenu = () => {
        setOpenMenu((prevState) => !prevState);
        if (openMenu) {
            setOpenMenu(false);
            document.body.style.overflow = '';
        }
    };

    const closeMenuForLogOut = () => {
        if (isAuthChecked) {
            logout();
        } else {
            navigateProfile();
        }
        toggleOpenCloseMenu();
    };

    //закрытие окон если курсор вне header
    useEffect(() => {
        let timeoutId;

        const handleClickOutside = (event) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
                if (headerRef.current && !headerRef.current.contains(event.target)) {
                    closeAllToggleMenu();
                }
            }, 100);
        };

        document.addEventListener('mousemove', handleClickOutside);
        return () => {
            document.removeEventListener('mousemove', handleClickOutside);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);

    const closeSearch = () => {
        onClickHandlerYM('/search');
        setOpenSearch((open) => !open);
        closeAllToggleMenu();
    };

    const isMobile = useMediaQuery({ query: '(max-width: 1080px)' });

    const onClickChangeTheme = () => {
        toggleTheme();

        if (theme === 'app_light_theme') ymHit(`clck-hdr-drk`);
        if (theme === 'app_dark_theme') ymHit(`clck-hdr-lght`);
    };

    const onClickHandlerYM = (href) => {
        if (href === '/') ymHit(`clck-hdr-mn`);
        if (href === '/logo') ymHit(`clck-hdr-logo`);
        if (href === '/analytics-and-news') ymHit(`clck-hdr-an`);
        if (href === '/market-map') ymHit(`clck-hdr-mrkt_map`);
        if (href === '/education') ymHit(`clck-hdr-educ`);
        if (href === '/contacts') ymHit(`clck-hdr-cntcts`);
        if (href === '/polezno-investoru') ymHit(`clck-hdr-invstr`);
        if (href === '/glossary') ymHit(`clck-hdr-glsr`);
        if (href === '/about-us') ymHit(`clck-hdr-abt`);
        if (href === '/profile-icon') ymHit(`clck-hdr-prfl-icon`);
        if (href === 'Ideas РФ') ymHit(`clck-hdr-tbls-ids_rus`);
        if (href === 'Ideas World') ymHit(`clck-hdr-tbls-ids_wrld`);
        if (href === 'Unity РФ') ymHit(`clck-hdr-tbls-unt_rus`);
        if (href === 'Unity World') ymHit(`clck-hdr-tbls-unt_wrld`);
        if (href === '/quit') ymHit(`clck-hdr-quit`);
        if (href === '/entry') ymHit(`clck-hdr-entry`);
        if (href === '/search') ymHit(`clck-hdr-srch`);
        if (href === '/menu-open') ymHit(`clck-hdr-menu-open`);
        if (href === '/menu-close') ymHit(`clck-hdr-menu-close`);
        if (href === '/screener') ymHit(`clck-hdr-scrnr`);
        if (href === '/subscriptions-header') ymHit(`clck-hdr-sbscrs`);
        if (href === 'products/ideas-russia') ymHit(`clck-hdr-trfs-ids_rus-drpdwn`);
        if (href === 'products/ideas-world') ymHit(`clck-hdr-trfs-ids_wrld-drpdwn`);
        if (href === 'products/ideas-all') ymHit(`clck-hdr-trfs-ids_all-drpdwn`);
        if (href === 'products/unity-russia') ymHit(`clck-hdr-trfs-unt_rus-drpdwn`);
        if (href === 'products/unity-world') ymHit(`clck-hdr-trfs-unt_wrld-drpdwn`);
        if (href === 'products/unity-all') ymHit(`clck-hdr-trfs-unt_all-drpdwn`);
        if (href === 'products/premium') ymHit(`clck-hdr-trfs-prm-drpdwn`);
    };

    if (!isMobile)
        return (
            <header ref={headerRef} className={styles.header}>
                {/*НА ВРЕМЯ АКЦИИ*/}
                <StockAllPages />
                {/*НА ВРЕМЯ АКЦИИ*/}

                <div className={styles.container}>
                    <NavLink to={'/'} className={styles.link} onClick={() => onClickHandlerYM('/logo')}>
                        <img className={styles.logo} src={InvestEraWideLogo} alt="Логотип Invest Era" loading="lazy" />
                    </NavLink>

                    {openSearch && <SearchInput setOpenSearch={setOpenSearch} setOpenMenu={setOpenMenu} />}
                    {!openSearch && (
                        <ul className={styles.textContainer}>
                            <div className={clsx(styles.headerUp)}>
                                <HeaderLink
                                    to="/analytics-and-news"
                                    onClick={() => onClickHandlerYM('/analytics-and-news')}
                                >
                                    Аналитика и новости
                                </HeaderLink>
                                <HeaderLink
                                    to="/market-map"
                                    onMouseEnter={closeAllToggleMenu}
                                    onClick={() => onClickHandlerYM('/market-map')}
                                >
                                    Карта рынка
                                </HeaderLink>

                                <HeaderLink
                                    to="/screener/all"
                                    onMouseEnter={closeAllToggleMenu}
                                    onClick={() => onClickHandlerYM('/screener')}
                                >
                                    Компании
                                </HeaderLink>
                                <HeaderDropdown
                                    debounceDelay={0}
                                    content={
                                        <div className={styles.dropdownContent}>
                                            {portfolio.map((portfolioItem) => (
                                                <NavLink
                                                    className={styles.dropdownItem}
                                                    key={portfolioItem.id}
                                                    to={'/tables/' + portfolioItem.navigate}
                                                    onClick={() => onClickHandlerYM(portfolioItem.ymHref)}
                                                >
                                                    {portfolioItem.name}
                                                </NavLink>
                                            ))}
                                        </div>
                                    }
                                >
                                    <p className={styles.headerDropdownTitle}>
                                        Портфели
                                        <img className={styles.arrowDown} src={ChevronDown} alt="Стрелка вниз" />
                                    </p>
                                </HeaderDropdown>
                            </div>

                            <div className={clsx(styles.headerUpDown)}>
                                <HeaderDropdown
                                    debounceDelay={0}
                                    content={
                                        <div className={styles.dropdownContent}>
                                            <NavLink
                                                className={styles.dropdownItem}
                                                to="/education"
                                                onClick={() => onClickHandlerYM('/education')}
                                            >
                                                Обучение инвестициям
                                            </NavLink>
                                            <NavLink
                                                className={styles.dropdownItem}
                                                to="/glossary"
                                                onClick={() => onClickHandlerYM('/glossary')}
                                            >
                                                Глоссарий
                                            </NavLink>
                                            <NavLink
                                                className={styles.dropdownItem}
                                                to="/polezno-investoru"
                                                onClick={() => onClickHandlerYM('/polezno-investoru')}
                                            >
                                                Полезно инвестору
                                            </NavLink>
                                        </div>
                                    }
                                >
                                    <p className={styles.headerDropdownTitle} onMouseEnter={closeAllToggleMenu}>
                                        Обучение
                                        <img className={styles.arrowDown} src={ChevronDown} alt="Стрелка вниз" />
                                    </p>
                                </HeaderDropdown>
                                <HeaderDropdown
                                    debounceDelay={0}
                                    content={
                                        <div className={styles.dropdownContent}>
                                            {products.map(
                                                (product, i) =>
                                                    i !== 0 && (
                                                        <NavLink
                                                            className={styles.dropdownItem}
                                                            key={product.id}
                                                            to={product.navigate}
                                                            onClick={() => onClickHandlerYM(product.navigate)}
                                                        >
                                                            {product.name}
                                                        </NavLink>
                                                    )
                                            )}
                                        </div>
                                    }
                                >
                                    <HeaderLink
                                        to="/products"
                                        onMouseEnter={closeAllToggleMenu}
                                        onClick={() => onClickHandlerYM('/products')}
                                    >
                                        Продукты
                                        <img className={styles.arrowDown} src={ChevronDown} alt="Стрелка вниз" />
                                    </HeaderLink>
                                </HeaderDropdown>
                                <HeaderLink
                                    to="/subscriptions"
                                    onMouseEnter={closeAllToggleMenu}
                                    onClick={() => onClickHandlerYM('/subscriptions-header')}
                                >
                                    Подписки
                                </HeaderLink>
                                <HeaderLink
                                    to="/contacts"
                                    onMouseEnter={closeAllToggleMenu}
                                    onClick={() => onClickHandlerYM('/contacts')}
                                >
                                    Контакты
                                </HeaderLink>
                                <HeaderLink
                                    to="/about-us"
                                    onMouseEnter={closeAllToggleMenu}
                                    onClick={() => onClickHandlerYM('/about-us')}
                                >
                                    О нас
                                </HeaderLink>
                            </div>
                        </ul>
                    )}

                    <div className={styles.authorizationWrapper}>
                        <div className={styles.searchLogo}>
                            <img src={SearchLogo} alt="Поиск" onClick={closeSearch} loading="lazy" />
                        </div>
                        <div className={`${styles.themeWrapper} ${openSearch ? styles.hidden : ''}`}>
                            <img
                                onClick={onClickChangeTheme}
                                className={styles.themeImg}
                                src={theme === 'app_light_theme' ? ActiveSunSvg : ActiveMoonSvg}
                                alt="Иконка солнца"
                                loading="lazy"
                                height={26}
                                width={26}
                            />
                        </div>
                        <NavLink
                            to={'/profile'}
                            className={styles.userLogo}
                            onClick={() => onClickHandlerYM('/profile-icon')}
                        >
                            <img src={UserLogo} alt="Пользователь" loading="lazy" />
                        </NavLink>
                        {location.pathname === '/authorization' || location.pathname === '/noaccess' ? null : (
                            <div className={styles.buttonWrapper} onClick={isAuthChecked ? logout : navigateProfile}>
                                {isAuthChecked ? 'Выйти' : 'Войти'}
                            </div>
                        )}
                    </div>
                </div>
                {isOpenMenuHoveredTraining && (
                    <TwoLevelMenu open={false} data={extraining} closeModal={closeAllToggleMenu} />
                )}
                {isOpenMenuHoveredPortfolio && (
                    <TwoLevelMenuOther open={false} data={portfolio} closeModal={closeAllToggleMenu} />
                )}
                {isAuthChecked && isOpenMenuHoveredProfile && (
                    <TwoLevelMenuOtherProfile
                        open={false}
                        data={profile}
                        closeModal={closeAllToggleMenu}
                        clazz={true}
                    />
                )}
                {isOpenMenuHoveredSubscriptions && (
                    <TwoLevelMenuSubscriptions open={false} data={products} closeModal={closeAllToggleMenu} />
                )}
            </header>
        );

    if (isMobile)
        return (
            <header className={`${styles.header} ${openMenu ? styles.open : ''}`}>
                {/*НА ВРЕМЯ АКЦИИ*/}
                <StockAllPages />
                {/*НА ВРЕМЯ АКЦИИ*/}
                <div className={styles.container}>
                    <div className={styles.containerUp}>
                        <NavLink
                            to={'/'}
                            className={styles.link}
                            onClick={() => {
                                if (openMenu) {
                                    toggleOpenCloseMenu();
                                }
                                onClickHandlerYM('/logo');
                            }}
                        >
                            <img
                                className={styles.logo}
                                src={InvestEraSmallLogo}
                                alt="Логотип Invest Era"
                                loading="lazy"
                                height={40}
                                width={40}
                            />
                        </NavLink>
                        {openSearch && <SearchInput setOpenSearch={setOpenSearch} setOpenMenu={setOpenMenu} />}
                        <div className={styles.containerDown}>
                            <div className={`${styles.themeWrapper} ${openSearch ? styles.hidden : ''}`}>
                                <img
                                    onClick={onClickChangeTheme}
                                    className={styles.themeImg}
                                    src={theme === 'app_light_theme' ? ActiveSunSvg : ActiveMoonSvg}
                                    alt="Иконка солнца"
                                    loading="lazy"
                                    height={26}
                                    width={26}
                                />
                            </div>
                            <div className={`${styles.searchLogo} ${openSearch ? styles.hidden : ''}`}>
                                <img
                                    src={SearchLogo}
                                    alt="Поиск"
                                    onClick={closeSearch}
                                    loading="lazy"
                                    height={24}
                                    width={24}
                                />
                            </div>
                            <NavLink
                                to={'/profile'}
                                onClick={() => {
                                    if (openMenu) {
                                        toggleOpenCloseMenu();
                                    }
                                    onClickHandlerYM('/profile-icon');
                                }}
                                className={`${styles.userLogo} ${openSearch ? styles.hidden : ''}`}
                            >
                                <img src={UserLogo} alt="Пользователь" loading="lazy" height={24} width={24} />
                            </NavLink>
                            <div
                                className={`${styles.menuText} ${openSearch ? styles.hidden : ''}`}
                                onClick={toggleMenu}
                            >
                                {openMenu ? <img src={Close} alt="Закрыть" loading="lazy" /> : 'Меню'}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: openMenu ? 'flex' : 'none' }} className={styles.down}>
                        <div className={styles.listsWrapper}>
                            <ul className={styles.list}>
                                <li onClick={toggleOpenCloseMenu} className={styles.listItem}>
                                    <NavLink
                                        to={'/analytics-and-news'}
                                        className={styles.linkMenu}
                                        onClick={() => onClickHandlerYM('/analytics-and-news')}
                                    >
                                        Аналитика и новости
                                    </NavLink>
                                </li>

                                <li onClick={toggleOpenCloseMenu} className={styles.listItem}>
                                    <NavLink
                                        to={'/market-map'}
                                        className={styles.linkMenu}
                                        onClick={() => onClickHandlerYM('/market-map')}
                                    >
                                        Карта рынка
                                    </NavLink>
                                </li>

                                <li className={styles.listItem}>
                                    <div className={styles.listItemImgWrapper}>
                                        <NavLink
                                            onClick={() => {
                                                toggleOpenCloseMenu();
                                                onClickHandlerYM('/screener');
                                            }}
                                            className={styles.linkMenu}
                                            to={`/screener/all`}
                                        >
                                            Компании
                                        </NavLink>
                                    </div>
                                </li>

                                <li
                                    className={styles.listItem}
                                    onClick={() => {
                                        toggleSubMenu('portfolio');
                                    }}
                                >
                                    <div className={styles.listItemImgWrapper}>
                                        Портфели
                                        <img
                                            src={openSubMenu.portfolio ? ArrowUp : ArrowDown}
                                            alt="Стрелка"
                                            loading="lazy"
                                        />
                                    </div>
                                    <ul className={`${styles.subList} ${openSubMenu.portfolio ? '' : styles.hidden}`}>
                                        {portfolio?.map((item) => (
                                            <li key={item.id}>
                                                <NavLink
                                                    onClick={() => {
                                                        toggleOpenCloseMenu();
                                                        onClickHandlerYM(item.name);
                                                    }}
                                                    className={styles.linkMenu}
                                                    to={`/tables/${item.navigate}`}
                                                >
                                                    {item.name}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>

                            <ul className={styles.list}>
                                <li
                                    className={styles.listItem}
                                    onClick={() => {
                                        toggleSubMenu('education');
                                    }}
                                >
                                    <div className={styles.listItemImgWrapper}>
                                        Обучение
                                        <img
                                            src={openSubMenu.education ? ArrowUp : ArrowDown}
                                            alt="Стрелка"
                                            loading="lazy"
                                        />
                                    </div>
                                    <ul className={`${styles.subList} ${openSubMenu.education ? '' : styles.hidden}`}>
                                        <li className={styles.listItem}>
                                            <div className={styles.listItemImgWrapper}>
                                                <NavLink
                                                    onClick={() => {
                                                        toggleOpenCloseMenu();
                                                        onClickHandlerYM('/education');
                                                    }}
                                                    className={styles.linkMenu}
                                                    to={`/education`}
                                                >
                                                    Обучение инвестициям
                                                </NavLink>
                                            </div>
                                        </li>
                                        <li onClick={toggleOpenCloseMenu} className={styles.listItem}>
                                            <NavLink
                                                className={styles.linkMenu}
                                                to={`/polezno-investoru`}
                                                onClick={() => onClickHandlerYM('/polezno-investoru')}
                                            >
                                                Полезно инвестору
                                            </NavLink>
                                        </li>
                                        <li onClick={toggleOpenCloseMenu} className={styles.listItem}>
                                            <NavLink
                                                className={styles.linkMenu}
                                                to={'/glossary'}
                                                onClick={() => onClickHandlerYM('/glossary')}
                                            >
                                                Глоссарий
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>

                                <li className={styles.listItem}>
                                    <div
                                        className={styles.listItemImgWrapper}
                                        onClick={() => toggleSubMenu('products')}
                                    >
                                        Продукты
                                        <img
                                            src={openSubMenu.products ? ArrowUp : ArrowDown}
                                            alt="Стрелка"
                                            loading="lazy"
                                        />
                                    </div>
                                    <ul className={`${styles.subList} ${openSubMenu.products ? '' : styles.hidden}`}>
                                        {products?.map((item) => (
                                            <li key={item.id}>
                                                <NavLink
                                                    onClick={() => {
                                                        toggleOpenCloseMenu();
                                                        onClickHandlerYM(item.navigate);
                                                    }}
                                                    className={styles.linkMenu}
                                                    to={`/${item.navigate}`}
                                                >
                                                    {item.name}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </li>

                                <li onClick={toggleOpenCloseMenu} className={styles.listItem}>
                                    <NavLink
                                        onClick={() => {
                                            toggleOpenCloseMenu();
                                            onClickHandlerYM('/subscriptions-header');
                                        }}
                                        className={styles.linkMenu}
                                        to={`/subscriptions`}
                                    >
                                        Подписки
                                    </NavLink>
                                </li>

                                <li onClick={toggleOpenCloseMenu} className={styles.listItem}>
                                    <NavLink
                                        className={styles.linkMenu}
                                        to={'/contacts'}
                                        onClick={() => onClickHandlerYM('/contacts')}
                                    >
                                        Контакты
                                    </NavLink>
                                </li>

                                <li onClick={toggleOpenCloseMenu} className={styles.listItem}>
                                    <NavLink
                                        className={styles.linkMenu}
                                        to={'/about-us'}
                                        onClick={() => onClickHandlerYM('/about-us')}
                                    >
                                        О нас
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.buttonWrapperMobile} onClick={closeMenuForLogOut}>
                            <div className={styles.authIcon}>
                                {isAuthChecked && <img src={DoorExit} alt="Иконка выхода" />}
                                {!isAuthChecked && <img src={DoorJoin} className="filter-white" alt="Иконка входа" />}
                            </div>
                            {isAuthChecked ? 'Выйти' : 'Войти'}
                        </div>
                    </div>
                </div>

                <div className={styles.buttonWrapper} onClick={closeMenuForLogOut}>
                    {isAuthChecked ? 'Выйти' : 'Войти'}
                </div>
            </header>
        );
}

export default AppHeaderMain;
