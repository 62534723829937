import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFinancialData } from '../../types/screener/screener-dividends.types';
import axios from 'axios';

// Определение состояния с типами
interface ScreenerDividendsState {
    data: IFinancialData | null;
    isLoading: boolean;
    error: string | null;
    ticker: string | null;
}

// Начальное состояние с указанием типов
const initialState: ScreenerDividendsState = {
    data: null,
    isLoading: true,
    error: null,
    ticker: null,
};

// Создаем асинхронный thunk для запроса данных
export const fetchScreenerDividends = createAsyncThunk<
    IFinancialData, // Тип успешного результата
    void, // Тип аргумента
    { rejectValue: string } // Тип значения отклоненного действия
>('screenerDividends/fetchFinancialData', async (_, { rejectWithValue, getState }) => {
    try {
        const ticker = (getState() as any).screener.information.main_info.ticker;
        const response = await axios(`/api/financial-data/get-data/?ticker=${ticker}&type=dividends`);

        const data: IFinancialData = response.data;
        return data;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

// Создаем slice для финансовых данных
const screenerDividendsSlice = createSlice({
    name: 'screenerDividends',
    initialState,
    reducers: {
        setTicker: (state, action: PayloadAction<string>) => {
            state.ticker = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchScreenerDividends.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchScreenerDividends.fulfilled, (state, action: PayloadAction<IFinancialData>) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchScreenerDividends.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
    },
});

export const { setTicker } = screenerDividendsSlice.actions;

export default screenerDividendsSlice.reducer;
