import { ConfigProvider } from 'antd';

const AntdThemeProvider = ({ children }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Tabs: {
                        itemColor: 'var(--primary-color)',
                        titleFontSize: 16,
                        itemActiveColor: '#047337',
                        itemSelectedColor: '#047337',
                        itemHoverColor: 'var(--primary-color)',
                        inkBarColor: '#047337',
                        cardPadding: '60px 200px',
                    },
                    Radio: {
                        colorPrimary: 'var(--color-accent-green)',
                        colorPrimaryHover: '#06813E',
                        colorPrimaryBorder: 'var(--color-accent-green)',
                        colorBgContainer: 'var(--color-bg-default-white)',
                        colorBorder: '#B9BDC4',
                        colorText: 'var(--color-text-primary)',
                    },
                },
                token: {
                    fontFamily: 'Montserrat, sans-serif',
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
};

export default AntdThemeProvider;
