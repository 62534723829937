import { CSSProperties, FC, useLayoutEffect, useRef, useState } from 'react';
import styles from './header-dropdown.module.scss';
import clsx from 'classnames';
import useDebounce from '@utils/custom-hooks';

type HeaderDropdownProps = {
    content: React.ReactNode;
    children: React.ReactNode;
    position?: 'bottom' | 'right';
    className?: string;
    debounceDelay?: number;
};

export const HeaderDropdown: FC<HeaderDropdownProps> = ({
    className,
    children,
    content,
    position = 'bottom',
    debounceDelay = 0,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentStyle, setContentStyle] = useState<CSSProperties | undefined>();
    const ref = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (!ref?.current) return;

        const { width, height } = ref.current.getBoundingClientRect();
        if (position === 'bottom') {
            setContentStyle({
                top: height + 'px',
            });
        } else {
            setContentStyle({
                top: 0,
                left: width + 'px',
            });
        }
    }, []);

    const isOpenDebounced = useDebounce(isOpen, debounceDelay);

    return (
        <div
            className={clsx({
                [styles.dropdown]: true,
                className: className,
                'dropdown-opened': isOpenDebounced,
                'dropdown-closed': !isOpenDebounced,
            })}
            ref={ref}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
        >
            <div
                className={clsx({
                    'dropdown-title': true,
                })}
            >
                {children}
            </div>
            {isOpenDebounced && (
                <div className={styles.content} style={contentStyle}>
                    {content}
                </div>
            )}
        </div>
    );
};
