import { FC, useCallback, useState } from 'react';
import styles from './settings.module.scss';
import clsx from 'classnames';

import SettingsIcon from '@images/settings.svg';
import { IeDropdown } from '@components/ui/ie-dropdown/ie-dropdown';
import { useAppDispatch, useAppSelector } from '@services/hooks/redux-hooks';
import { setActiveTab, TabsType } from '@services/reducers/companies-compare-slice';
import { CellSettings } from '@pages/companies-compare/cell-settings';
import { useMediaQuery } from 'react-responsive';
import { ColumnSettings } from '@pages/companies-compare/column-settings';
import { BottomSheet } from '@components/ui/bottom-sheet/bottom-sheet';
import { Button } from '@common/button/button';

type SettingsProps = {
    className?: string;
};

export const Settings: FC<SettingsProps> = ({ className }) => {
    const activeTab = useAppSelector((state) => state.companiesCompare.activeTab);
    const dispatch = useAppDispatch();

    const dispatchSetActiveTab = useCallback((tab: TabsType) => dispatch(setActiveTab(tab)), [dispatch]);

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const [showColumnSettings, setShowColumnSettings] = useState(false);

    const closeColumnSettings = useCallback(() => setShowColumnSettings(false), []);

    return (
        <div
            className={clsx({
                [styles.settings]: true,
                [String(className)]: className,
            })}
        >
            <div className={styles.tabs}>
                <Tab
                    activeTab={activeTab}
                    setActiveTab={dispatchSetActiveTab}
                    name="main"
                    title={isMobile ? 'Осн. показатели' : 'Основные показатели'}
                />
                <Tab
                    activeTab={activeTab}
                    setActiveTab={dispatchSetActiveTab}
                    name="financial"
                    title={isMobile ? 'Фин. коэфф.' : 'Финансовые коэффициенты'}
                />
            </div>
            <div className={styles.params}>
                {!isMobile && (
                    <>
                        <IeDropdown
                            content={<ColumnSettings onClose={closeColumnSettings} />}
                            open={showColumnSettings}
                            onOpenChange={setShowColumnSettings}
                        >
                            <button className={styles.paramsButton}>
                                <img className={styles.paramsIcon} src={SettingsIcon} alt="" />
                                Столбцы
                            </button>
                        </IeDropdown>
                        <IeDropdown content={<CellSettings />}>
                            <button className={styles.paramsButton}>
                                <img className={styles.paramsIcon} src={SettingsIcon} alt="" />
                                Настройки
                            </button>
                        </IeDropdown>
                    </>
                )}
                {isMobile && <MobileSettingsButtons />}
            </div>
        </div>
    );
};

const MobileSettingsButtons: FC = () => {
    const [isColumnsOpen, setIsColumnsOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    return (
        <>
            <button className={styles.paramsButton} onClick={() => setIsColumnsOpen(true)}>
                <img className={styles.paramsIcon} src={SettingsIcon} alt="" />
                Столбцы
            </button>
            <button className={styles.paramsButton} onClick={() => setIsSettingsOpen(true)}>
                <img className={styles.paramsIcon} src={SettingsIcon} alt="" />
                Настройки
            </button>
            <BottomSheet open={isColumnsOpen} onClose={() => setIsColumnsOpen(false)}>
                <ColumnSettings onClose={() => setIsColumnsOpen(false)} />
            </BottomSheet>
            <BottomSheet open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)}>
                <CellSettings />
                <Button className={styles.mobileApplyButton} onClick={() => setIsSettingsOpen(false)}>
                    Применить
                </Button>
            </BottomSheet>
        </>
    );
};

type TabProps = {
    name: TabsType;
    title: string;
    activeTab: TabsType;
    setActiveTab: (activeTab: TabsType) => void;
};

const Tab: FC<TabProps> = ({ setActiveTab, activeTab, title, name }) => (
    <button
        onClick={() => setActiveTab(name)}
        className={clsx({
            [styles.tab]: true,
            [styles.active]: activeTab === name,
        })}
    >
        {title}
    </button>
);
