import { FC } from 'react';
import { Radio } from 'antd';
import { useAppDispatch, useAppSelector } from '@services/hooks/redux-hooks';
import { setCellSize, setFontSize } from '@services/reducers/companies-compare-slice';
import styles from './cell-settings.module.scss';
import { LOCALSTORAGE_KEY_CELL_SIZE, LOCALSTORAGE_KEY_FONT_SIZE } from 'consts/companies-compare';

export const CellSettings: FC = () => {
    const dispatch = useAppDispatch();
    const { fontSize, cellSize } = useAppSelector((state) => state.companiesCompare);

    return (
        <div className={styles.cellSettings}>
            <p className={styles.title}>Размер ячеек</p>
            <Radio.Group
                className={styles.radioGroup}
                value={cellSize}
                onChange={(e) => {
                    dispatch(setCellSize(e.target.value));
                    localStorage.setItem(LOCALSTORAGE_KEY_CELL_SIZE, e.target.value);
                }}
            >
                <Radio.Button value="big">Большие</Radio.Button>
                <Radio.Button value="medium">Средние</Radio.Button>
                <Radio.Button value="small">Маленькие</Radio.Button>
            </Radio.Group>
            <p className={styles.title}>Размер шрифта</p>
            <Radio.Group
                className={styles.radioGroup}
                value={fontSize}
                onChange={(e) => {
                    dispatch(setFontSize(e.target.value));
                    localStorage.setItem(LOCALSTORAGE_KEY_FONT_SIZE, e.target.value);
                }}
            >
                <Radio.Button value="big">Большой</Radio.Button>
                <Radio.Button value="medium">Средний</Radio.Button>
                <Radio.Button value="small">Маленький</Radio.Button>
            </Radio.Group>
        </div>
    );
};
