import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@services/hooks/redux-hooks';
import { IeSwitch } from '@components/ui/ie-switch/ie-switch';
import { addHiddenColumn, removeHiddenColumn } from '@services/reducers/companies-compare-slice';
import styles from './column-settings.module.scss';

type ColumnsSettingsProps = {
    onClose: () => void;
};

export const ColumnSettings: FC<ColumnsSettingsProps> = ({ onClose }) => {
    const { hiddenColumns, columns } = useAppSelector((state) => state.companiesCompare);
    const dispatch = useAppDispatch();

    return (
        <div>
            {columns.map((column) => (
                <IeSwitch
                    className={styles.switch}
                    checked={!hiddenColumns.includes(String(column.key))}
                    disabled={column.unhidded}
                    onChange={(checked) => {
                        if (checked) dispatch(removeHiddenColumn(String(column.key)));
                        else dispatch(addHiddenColumn(String(column.key)));
                    }}
                    key={column.key}
                    title={String(column.title)}
                />
            ))}
        </div>
    );
};
