import { FC, useEffect, useMemo } from 'react';
import styles from './companies-compare.module.scss';
import { BreadcrumbNavigation } from '@common/breadcrumb-navigation/breadcrumb-navigation';
import { Settings } from './settings';
import { Header } from './header';
import { useAppDispatch, useAppSelector } from '@services/hooks/redux-hooks';
import { fetchCompanyComparison, setFetchedCompanies, SizeType } from '@services/reducers/companies-compare-slice';
import { ConfigProvider, Empty, Table } from 'antd';
import Arrow from '@images/compare-companies-arrow.svg';
import Preloader from '@common/preloader/preloader';
import { CustomColumnsType } from '@pages/companies-compare/types';
import { CompanyComparisonType } from 'types/companies-compare.types';

export type CompanyToCompareType = {
    name: string;
    ticker: string;
};

export const CompaniesCompare: FC = () => {
    const {
        data: fetchedCompanies,
        isLoading: isFetchingCompanies,
        companiesToCompare,
        fontSize,
        cellSize,
        columns,
        hiddenColumns,
    } = useAppSelector((state) => state.companiesCompare);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (companiesToCompare.length < 2) {
            dispatch(setFetchedCompanies([]));
            return;
        }

        const controller = new AbortController();
        const signal = controller.signal;

        dispatch(
            fetchCompanyComparison({
                tickers: companiesToCompare.map((company) => company.ticker),
                signal,
            })
        );

        return () => {
            controller.abort();
        };
    }, [companiesToCompare, dispatch]);

    const modifiedColumns = useMemo<CustomColumnsType<CompanyComparisonType>>(() => {
        return columns.map((column, rowIndex) => ({
            ...column,
            title: () => {
                return (
                    <div>
                        {String(column.title)}
                        {column.additionalHeaderText && (
                            <div style={{ color: 'var(--color-text-third)' }}>{column.additionalHeaderText}</div>
                        )}
                    </div>
                );
            },
            render: (text, record, index) => {
                if (!text) return '-';

                let resultText = text;
                let color = 'var(--color-text-primary)';

                if (column.redGreen) {
                    color = +text >= 0 ? 'var(--color-supportive-success)' : 'var(--color-supportive-danger)';
                }
                if (column.formatBillion) {
                    resultText = (text / 1e9).toFixed(2);
                }
                if (column.toFixed) {
                    resultText = (+text).toFixed(2);
                }
                if (column.percentAtEnd) {
                    resultText += '%';
                }

                return <span style={{ color: color }}>{resultText}</span>;
            },
            hidden: hiddenColumns.includes(String(column.key)),
        }));
    }, [columns, hiddenColumns]);

    return (
        <div className={styles.compareCompanies}>
            <BreadcrumbNavigation
                className={styles.breadcrumbs}
                links={[{ url: '/', label: 'Главная' }]}
                forBg="light"
            />
            <Header />

            <Settings />

            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            headerBorderRadius: 16,
                        },
                    },
                }}
            >
                <Table
                    className={styles.table}
                    style={{ '--font-size': fontSizeMap[fontSize], '--cell-size': cellSizeMap[cellSize] } as any}
                    columns={modifiedColumns}
                    rowKey="ticker"
                    scroll={{ x: 'auto' }}
                    dataSource={fetchedCompanies}
                    pagination={false}
                    loading={{ spinning: isFetchingCompanies, indicator: <Preloader className={styles.loader} /> }}
                    locale={{
                        emptyText: (
                            <Empty
                                description={
                                    <>
                                        <p className={styles.selectCompaniesToCompare}>
                                            Выберите от 2 до 10 компаний чтобы начать сравнивать
                                        </p>
                                        <img className={styles.arrow} src={Arrow} alt="" />
                                    </>
                                }
                                image={null}
                            />
                        ),
                    }}
                />
            </ConfigProvider>
        </div>
    );
};

const fontSizeMap: Record<SizeType, string> = {
    big: '18px',
    medium: '14px',
    small: '11px',
};

const cellSizeMap: Record<SizeType, string> = {
    big: '200px',
    medium: '150px',
    small: 'min-width',
};
