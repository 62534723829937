import { FC, useCallback, useEffect, useState } from 'react';
import styles from './header.module.scss';
import { Button } from '@common/button/button';
import Trash from '@images/trash.svg';
import Plus from '@images/plus.svg';
import { useAppDispatch, useAppSelector } from '@services/hooks/redux-hooks';
import { fetchRussianCompanies } from '@services/reducers/russian-companies-slice';
import { IeDropdown } from '@components/ui/ie-dropdown/ie-dropdown';
import { AddCompanies } from '@pages/companies-compare/add-companies';
import { RemoveCompanies } from '@pages/companies-compare/remove-companies';
import { CompanyToCompareType } from '@pages/companies-compare/companies-compare';
import { setCompaniesToCompare } from '@services/reducers/companies-compare-slice';
import { useMediaQuery } from 'react-responsive';
import { BottomSheet } from '@components/ui/bottom-sheet/bottom-sheet';

export const Header: FC = () => {
    const dispatch = useAppDispatch();
    const companiesToCompare = useAppSelector((state) => state.companiesCompare.companiesToCompare);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    const dispatchSetCompaniesToCompare = useCallback(
        (companiesToCompare: CompanyToCompareType[]) => dispatch(setCompaniesToCompare(companiesToCompare)),
        [dispatch]
    );

    useEffect(() => {
        dispatch(fetchRussianCompanies());
    }, []);

    return (
        <div className={styles.header}>
            <h1 className={styles.title}>Сравнение компаний</h1>
            {!isMobile && (
                <>
                    <IeDropdown
                        content={
                            <RemoveCompanies
                                companiesToCompare={companiesToCompare}
                                setCompaniesToCompare={dispatchSetCompaniesToCompare}
                            />
                        }
                    >
                        <Button className={styles.deleteCompanyButton} variant="outlined">
                            <img className={styles.trash} src={Trash} alt="" />
                            Удалить компанию
                        </Button>
                    </IeDropdown>
                    <IeDropdown
                        content={
                            <AddCompanies
                                setCompaniesToCompare={dispatchSetCompaniesToCompare}
                                companiesToCompare={companiesToCompare}
                            />
                        }
                    >
                        <Button className={styles.addCompanyButton}>
                            <img className={styles.plus} src={Plus} alt="" />
                            Добавить компанию
                        </Button>
                    </IeDropdown>
                </>
            )}
            {isMobile && (
                <MobileHeader
                    setCompaniesToCompare={dispatchSetCompaniesToCompare}
                    companiesToCompare={companiesToCompare}
                />
            )}
        </div>
    );
};

interface MobileHeaderProps {
    setCompaniesToCompare: (companies: CompanyToCompareType[]) => void;
    companiesToCompare: CompanyToCompareType[];
}

const MobileHeader: FC<MobileHeaderProps> = ({ setCompaniesToCompare, companiesToCompare }) => {
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [isRemoveOpen, setIsRemoveOpen] = useState(false);

    return (
        <>
            <Button className={styles.deleteCompanyButton} variant="outlined" onClick={() => setIsRemoveOpen(true)}>
                <img className={styles.trash} src={Trash} alt="" />
                Удалить компанию
            </Button>
            <Button className={styles.addCompanyButton} onClick={() => setIsAddOpen(true)}>
                <img className={styles.plus} src={Plus} alt="" />
                Добавить компанию
            </Button>
            <BottomSheet open={isAddOpen} onClose={() => setIsAddOpen(false)} height="413px">
                <AddCompanies setCompaniesToCompare={setCompaniesToCompare} companiesToCompare={companiesToCompare} />
            </BottomSheet>
            <BottomSheet open={isRemoveOpen} onClose={() => setIsRemoveOpen(false)}>
                <RemoveCompanies
                    setCompaniesToCompare={setCompaniesToCompare}
                    companiesToCompare={companiesToCompare}
                />
            </BottomSheet>
        </>
    );
};
