import { NavLink, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import clsx from 'classnames';
import { scrollToAnchor } from '@common/scroll-to-anchor/scroll-to-anchor';
import { ymHit } from '@utils/ym-hit.js';
import InvestEraWideLogo from '@images/layout/ie-logo.svg';
import Telegram from '@images/footer-logo-youtube.svg';
import YouTube from '@images/tg-logo-footer.svg';
import styles from './footer-main.module.css';

function FooterMain() {
    const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const onClickHandlerYM = (href) => {
        if (href === '/logo') ymHit(`clck-ftr-main_logo`);
        if (href === '/analytics-and-news') ymHit(`clck-ftr-an`);
        if (href === '/market-map') ymHit(`clck-ftr-mrkt_map`);
        if (href === '/education') ymHit(`clck-ftr-educ`);
        if (href === '/webinars') ymHit(`clck-ftr-wbrns`);
        if (href === '/about-us') ymHit(`clck-ftr-abt`);
        if (href === '/screener') ymHit(`clck-ftr-scrnr`);
        if (href === '/telegram') ymHit(`clck-ftr-tg`);
        if (href === '/youtube') ymHit(`clck-ftr-yt`);
        if (href === '/privacy-policy') ymHit(`clck-ftr-privacy_policy`);
        if (href === '/user-agreement') ymHit(`clck-ftr-user_agreement`);
        if (href === '/ie_contact') ymHit(`clck-ftr-chat_support`);
        if (href === '/faq') ymHit(`clck-ftr-faq`);
    };

    return (
        <footer className={styles.footer}>
            {!isMobile ? (
                <div className={styles.wrapper}>
                    <div className={styles.info}>
                        <NavLink
                            to="/"
                            className={styles.footerLogo}
                            onClick={() => {
                                scrollToTop();
                                onClickHandlerYM('/logo');
                            }}
                        >
                            <img src={InvestEraWideLogo} alt="Invest Era лого" loading="lazy" height={40} width={174} />
                        </NavLink>

                        <div className={`${styles.desc} ${styles.desc2}`}>
                            Обращаем внимание: все материалы, <br /> представленные на сайте, не являются <br />{' '}
                            инвестиционной рекомендацией.
                        </div>
                        <div className={`${styles.desc} ${styles.desc3}`}>© 2021 - 2024 «ИП Артём Николаев»</div>
                    </div>
                    <div className={styles.navigate}>
                        <div className={styles.navigateFirst}>
                            <NavLink
                                className={styles.navigateLink}
                                to={'/analytics-and-news'}
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/analytics-and-news');
                                }}
                            >
                                Аналитика и новости
                            </NavLink>
                            <NavLink
                                className={styles.navigateLink}
                                to={'/market-map'}
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/market-map');
                                }}
                            >
                                Карта рынка
                            </NavLink>
                            <NavLink
                                className={styles.navigateLink}
                                to={'/screener/all'}
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/screener');
                                }}
                            >
                                Компании
                            </NavLink>
                            <NavLink
                                className={styles.navigateLink}
                                to={'/#faq'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClickHandlerYM('/faq');
                                    if (window.location.pathname === '/') {
                                        scrollToAnchor('faq', 'start');
                                        return;
                                    }
                                    scrollToTop();
                                    navigate('/#faq', { state: { block: 'start' } });
                                }}
                            >
                                F.A.Q.
                            </NavLink>
                        </div>
                        <div className={styles.navigateSecond}>
                            <NavLink
                                className={styles.navigateLink}
                                to={'/education'}
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/education');
                                }}
                            >
                                Обучение
                            </NavLink>
                            <NavLink
                                className={styles.navigateLink}
                                to={'/webinars'}
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/webinars');
                                }}
                            >
                                Вебинары
                            </NavLink>
                            <NavLink
                                className={styles.navigateLink}
                                to={'/about-us'}
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/about-us');
                                }}
                            >
                                О нас
                            </NavLink>
                            <NavLink
                                className={clsx(styles.navigateLink, styles.tgLink)}
                                to={'https://t.me/ie_contact'}
                                target="_blank"
                                onClick={() => {
                                    onClickHandlerYM('/ie_contact');
                                }}
                            >
                                <span>Чат поддержки</span>
                            </NavLink>
                        </div>
                    </div>
                    <div className={styles.contacts}>
                        <div className={styles.contactsLink}>
                            <a
                                href="https://t.me/+Avs2bsQ5mQk4N2My"
                                className={styles.youtube}
                                target={'_blank'}
                                rel="noopener noreferrer"
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/telegram');
                                }}
                            >
                                <img src={Telegram} alt="Telegram" loading="lazy" height={40} width={40} />
                            </a>
                            <a
                                href="https://www.youtube.com/@investera3816"
                                className={styles.youtube}
                                target={'_blank'}
                                rel="noopener noreferrer"
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/youtube');
                                }}
                            >
                                <img src={YouTube} alt="YouTube" loading="lazy" height={40} width={40} />
                            </a>
                        </div>

                        <div className={styles.agreements}>
                            <NavLink
                                className={styles.infoLink}
                                to={'/info/politika-konfidentsialnosti'}
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/privacy-policy');
                                }}
                            >
                                Политика конфиденциальности
                            </NavLink>
                            <NavLink
                                className={styles.infoLink}
                                to={
                                    '/info/dogovor-publichnoj-oferti-na-okazanie-informatsionno-konsultatsionnikh-uslug'
                                }
                                onClick={() => {
                                    scrollToTop();
                                    onClickHandlerYM('/user-agreement');
                                }}
                            >
                                Пользовательское соглашение
                            </NavLink>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.wrapper}>
                    <NavLink
                        to="/"
                        className={styles.footerLogo}
                        onClick={() => {
                            scrollToTop();
                            onClickHandlerYM('/logo');
                        }}
                    >
                        <img src={InvestEraWideLogo} alt="Invest Era лого" loading="lazy" height={40} width={150} />
                    </NavLink>

                    <div className={styles.contactsLink}>
                        <a
                            href="https://t.me/ie_contact"
                            className={styles.youtube}
                            target={'_blank'}
                            rel="noopener noreferrer"
                            onClick={() => onClickHandlerYM('/telegram')}
                        >
                            <img src={Telegram} alt="Telegram" loading="lazy" height={40} width={40} />
                        </a>
                        <a
                            href="https://www.youtube.com/@investera3816"
                            className={styles.youtube}
                            target={'_blank'}
                            rel="noopener noreferrer"
                            onClick={() => onClickHandlerYM('/youtube')}
                        >
                            <img src={YouTube} alt="YouTube" loading="lazy" height={40} width={40} />
                        </a>
                    </div>
                    <div className={styles.navigate}>
                        <NavLink
                            className={styles.navigateLink}
                            to={'/analytics-and-news'}
                            onClick={() => {
                                scrollToTop();
                                onClickHandlerYM('/analytics-and-news');
                            }}
                        >
                            Аналитика и новости
                        </NavLink>
                        <NavLink
                            className={styles.navigateLink}
                            to={'/market-map'}
                            onClick={() => {
                                scrollToTop();
                                onClickHandlerYM('/market-map');
                            }}
                        >
                            Карта рынка
                        </NavLink>
                        <NavLink
                            className={styles.navigateLink}
                            to={'/screener/all'}
                            onClick={() => {
                                scrollToTop();
                                onClickHandlerYM('/screener');
                            }}
                        >
                            Компании
                        </NavLink>
                        <NavLink
                            className={styles.navigateLink}
                            to={'#faq'}
                            onClick={(e) => {
                                e.preventDefault();
                                onClickHandlerYM('/faq');
                                if (window.location.pathname === '/') {
                                    scrollToAnchor('faq', 'start');
                                    return;
                                }
                                scrollToTop();
                                navigate('/#faq', { state: { block: 'start' } });
                            }}
                        >
                            F.A.Q.
                        </NavLink>
                        <NavLink
                            className={styles.navigateLink}
                            to={'/education'}
                            onClick={() => {
                                scrollToTop();
                                onClickHandlerYM('/education');
                            }}
                        >
                            Обучение
                        </NavLink>
                        <NavLink
                            className={styles.navigateLink}
                            to={'/webinars'}
                            onClick={() => {
                                scrollToTop();
                                onClickHandlerYM('/webinars');
                            }}
                        >
                            Вебинары
                        </NavLink>
                        <NavLink
                            className={styles.navigateLink}
                            to={'/about-us'}
                            onClick={() => {
                                scrollToTop();
                                onClickHandlerYM('/about-us');
                            }}
                        >
                            О нас
                        </NavLink>
                        <NavLink
                            className={styles.navigateLink}
                            to={'https://t.me/ie_contact'}
                            target="_blank"
                            onClick={() => {
                                onClickHandlerYM('/ie_contact');
                            }}
                        >
                            Чат поддержки
                        </NavLink>
                    </div>

                    <div className={`${styles.desc} ${styles.desc2}`}>
                        Обращаем внимание: все материалы, представленные на сайте, не являются инвестиционной
                        рекомендацией.
                    </div>
                    <div className={styles.agreements}>
                        <NavLink
                            className={styles.infoLink}
                            to={'/info/politika-konfidentsialnosti'}
                            onClick={() => {
                                scrollToTop();
                                onClickHandlerYM('/privacy-policy');
                            }}
                        >
                            Политика конфиденциальности
                        </NavLink>
                        <NavLink
                            className={styles.infoLink}
                            to={'/info/dogovor-publichnoj-oferti-na-okazanie-informatsionno-konsultatsionnikh-uslug'}
                            onClick={() => {
                                scrollToTop();
                                onClickHandlerYM('/user-agreement');
                            }}
                        >
                            Пользовательское соглашение
                        </NavLink>
                    </div>
                    <div className={`${styles.desc} ${styles.desc3}`}>
                        © 2021 - 2024 «ИП Артём Николаев» <br /> Все права защищены.
                    </div>
                </div>
            )}
        </footer>
    );
}

export default FooterMain;
