import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { IInvestIdeasState, IInvestIdea } from '../../types/screener/invest-ideas.types';

// Async thunk to fetch invest ideas
export const fetchInvestIdeas = createAsyncThunk<IInvestIdea[]>('investIdeas/fetchInvestIdeas', async () => {
    const response = await axios.get('/api/invest-ideas/get-invest-idea/');
    return response.data;
});

const initialState: IInvestIdeasState = {
    data: [],
    isLoading: false,
    error: null,
};

const investIdeasSlice = createSlice({
    name: 'investIdeas',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInvestIdeas.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchInvestIdeas.fulfilled, (state, action: PayloadAction<IInvestIdea[]>) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchInvestIdeas.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Error loading invest ideas';
            });
    },
});

export default investIdeasSlice.reducer;
