import { createContext, useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCountryPoints } from '../reducers/cards-filtred-slice';

const CountryFilterContext = createContext();

export const useActiveButtonCountryTagChoice = () => {
    return useContext(CountryFilterContext);
};

const ActiveCountryTagProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [popUpChoice, setPopUpChoice] = useState('');
    const [popUpExitVision, setPopUpExitVision] = useState(false);
    const [popUpCookieVision, setPopUpCookieVision] = useState(true);
    const [backendErrorCheck, setBackendErrorCheck] = useState(false);
    const mountedFilters = useRef(false);

    const toggleCountry = (val) => {
        if (val === '?country_tag=-1') {
            dispatch(setCountryPoints([{ id: -1 }]));
        }
        if (val === '?country_tag=1') {
            dispatch(setCountryPoints([{ id: 1 }]));
        }

        setPopUpChoice(val);
    };

    return (
        <CountryFilterContext.Provider
            value={{
                popUpChoice: popUpChoice,
                toggleCountry,
                popUpExitVision,
                setPopUpExitVision,
                popUpCookieVision,
                setPopUpCookieVision,
                backendErrorCheck,
                setBackendErrorCheck,
                mountedFilters,
            }}
        >
            {children}
        </CountryFilterContext.Provider>
    );
};

export default ActiveCountryTagProvider;
