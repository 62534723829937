import { FC } from 'react';
import { Switch, SwitchProps } from 'antd';

import styles from './ie-switch.module.scss';
import clsx from 'classnames';

type IeSwitchProps = SwitchProps;

export const IeSwitch: FC<IeSwitchProps> = ({ className, title, ...otherProps }) => {
    return (
        <div
            className={clsx({
                [styles.switchContainer]: true,
                [String(className)]: className,
            })}
        >
            <Switch className={styles.switch} {...otherProps} />
            <p className={styles.switchTitle}>{title}</p>
        </div>
    );
};
