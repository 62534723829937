import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categoryPoints: '',
    countryPoints: '',
    sectorPoints: '',
    companyPoints: '',
    orderingPoints: '&ordering=-pub_date',
    analysisPoints: '',
    subscriptionPoints: '',
    bondsPoints: '',
    changePortfolios: '',
    changeNews: '',
};

const cardsFiltredSlice = createSlice({
    name: 'cardsFiltred',
    initialState,
    reducers: {
        setCategoryPoints: (state, action) => {
            const apiConcat = (objCategory) => {
                if (objCategory.length === 0) {
                    return '';
                }
                if (action.payload.length === 2) {
                    return `&page_category__in=${objCategory
                        .map((el) => `${el.id}`)
                        .sort((a, b) => a - b)
                        .join(',')}`;
                }
                if (action.payload.length === 1) {
                    return `&page_category=${objCategory.map((el) => `${el.id}`)}`;
                }
            };
            state.categoryPoints = apiConcat(action.payload);
        },
        setCountryPoints: (state, action) => {
            state.countryPoints = action.payload.map((el) => `&country_tag=${el.id}`).join('');
            localStorage.setItem('countryPoints', state.countryPoints);
        },
        setSectorPoints: (state, action) => {
            state.sectorPoints = action.payload.map((el) => `&sector=${el.id}`).join('');
        },
        setCompanyPoints: (state, action) => {
            state.companyPoints = action.payload.map((el) => `&company_tag=${el.id}`).join('');
        },
        setOrderingPoints: (state, action) => {
            state.orderingPoints = `&ordering=${action.payload}`;
        },
        setClearAllFiltersCard: (state) => {
            state.categoryPoints = '';
            state.countryPoints = localStorage.getItem('countryPoints') || '';
            state.sectorPoints = '';
            state.companyPoints = '';
            state.orderingPoints = '&ordering=-pub_date';
            state.analysisPoints = '';
            state.subscriptionPoints = '';
            state.bondsPoints = '';
            state.changePortfolios = '';
            state.changeNews = '';
        },
        setClearCompanyPoints: (state) => {
            state.companyPoints = '';
        },
        setAnalysisPoints: (state, action) => {
            state.analysisPoints = action.payload;
        },
        setSubscriptionPoints: (state, action) => {
            state.subscriptionPoints = action.payload;
        },
        setBondsPoints: (state, action) => {
            state.bondsPoints = action.payload;
        },
        setChangePortfoliosPoints: (state, action) => {
            state.changePortfolios = action.payload;
        },
        setChangeNewsPoints: (state, action) => {
            state.changeNews = action.payload;
        },
    },
});

export const {
    setCategoryPoints,
    setCountryPoints,
    setSectorPoints,
    setCompanyPoints,
    setOrderingPoints,
    setClearAllFiltersCard,
    setClearCompanyPoints,
    setAnalysisPoints,
    setSubscriptionPoints,
    setBondsPoints,
    setChangePortfoliosPoints,
    setChangeNewsPoints,
} = cardsFiltredSlice.actions;

export default cardsFiltredSlice.reducer;
