import { lazy } from 'react';
import { lazyNamed } from '@utils/lazy-named';

export const MarketMap = lazy(() => import('@pages/market-map/market-map'));
export const Glossary = lazy(() => import('@pages/glossary/glossary'));
export const SubscriptionsPage = lazy(() => import('@pages/subscriptions-page/subscriptions-page'));
export const AnalyticsAndNews = lazy(() => import('@pages/analytics-and-news/analytics-and-news'));
export const LayoutMain = lazy(() => import('./layout-main/layout-main'));
export const AppMain = lazy(() => import('@pages/app-main/app-main'));
export const Contacts = lazyNamed(() => import('@pages/contacts/contacts'), 'Contacts');
export const AuthorizationPage = lazy(() => import('@pages/app-authorization/app-authorization-page'));
export const AboutCard = lazy(() => import('@pages/analytics-and-news/about-card/about-card'));
export const Education = lazy(() => import('@pages/education/education'));
export const LessonsPage = lazy(() => import('@pages/education/lessons-page/lessons-page'));
export const Lesson = lazy(() => import('@pages/education/lesson/lesson'));
export const Tables = lazy(() => import('@pages/tables/tables-column'));
export const PageInfo = lazy(() => import('@pages/page-info/page-info'));
export const Profile = lazy(() => import('@pages/profile/profile'));
export const PageInfoUrl = lazy(() => import('@pages/page-info/page-info-url/page-url-info'));
export const ProductsEcosystem = lazy(() => import('@pages/products/products-ecosystem/products-ecosystem'));
export const ProductSub = lazyNamed(() => import('@pages/products/product-sub-url'), 'ProductSub');
export const NoAccess = lazy(() => import('@pages/no-access-page/no-access-page'));
export const Error500 = lazy(() => import('@pages/error-pages/error-500/error-500'));
export const Error403 = lazy(() => import('@pages/error-pages/error-403/error-403'));
export const Webinars = lazy(() => import('@pages/webinars/webinars'));
export const AboutUs = lazy(() => import('@pages/about-us/about-us'));
export const TableNew = lazy(() => import('@pages/tables/table-new/table-new'));
export const BlogPage = lazy(() => import('@pages/blog-page/blog-page'));
export const BlogForLink = lazy(() => import('@pages/blog-page/ui/blog-for-link/blog-for-link'));
export const PageForAddInfo = lazy(() => import('@pages/page-for-add-info/page-for-add-info'));
export const ScreenerDivider = lazyNamed(() => import('@pages/screener/screener-divider'), 'ScreenerDivider');
export const ActiveButtonProvider = lazy(() => import('@services/context/active-button-context'));
export const ActiveCountryTagProvider = lazy(() => import('@services/context/country-filter-context'));
export const ActiveFiltersProvider = lazy(() => import('@services/context/active-filters-context'));
export const ProfileProvider = lazy(() => import('@services/context/profile-context'));
export const PageInfoProvider = lazy(() => import('@services/context/page-info-context'));
export const WebinarProvider = lazy(() => import('@services/context/webinar-context'));
export const ExternalRedirect = lazy(() => import('./external-redirect/external-redirect') as any);
export const PreloaderMain = lazy(() => import('./preloader-main/preloader-main'));
export const InvestIdeas = lazy(() => import('@components/pages/invest-ideas/invest-ideas'));
export const PopUpTimeoutAnalitycs = lazy(
    () => import('@common/pop-ups/pop-up-timeout/pop-up-timeout-analitycs/pop-up-timeout-analitycs')
);
export const PopUpTimeOutTables = lazy(
    () => import('@common/pop-ups/pop-up-timeout/pop-up-timeout-tables/pop-up-timeout-tables')
);
export const PopUpTimeoutEmail = lazy(
    () => import('@common/pop-ups/pop-up-timeout/pop-up-timeout-email/pop-up-timeout-email')
);
