import { FC } from 'react';
import { Popover, PopoverProps } from 'antd';

import './ie-dropdown.scss';
import clsx from 'classnames';

type IeDropdownProps = PopoverProps;

export const IeDropdown: FC<IeDropdownProps> = ({ className, ...otherProps }) => {
    return (
        <Popover
            rootClassName={clsx({
                'ie-dropdown': true,
                [String(className)]: className,
            })}
            arrow={false}
            trigger="click"
            placement="bottomRight"
            {...otherProps}
        />
    );
};
