export const extraining = {
    begin: {
        title1: 'Начинающим',
        beginnerData: [
            {
                id: 1,
                name: 'Личные финансы',
                navigate: '/education/lichnie-finansi',
            },
            { id: 2, name: 'Облигации', navigate: '/education/obligatsii-18' },
            {
                id: 3,
                name: 'Основы инвестиций',
                navigate: '/education/osnovi-investitsij',
            },
            {
                id: 4,
                name: 'Составляем портфель',
                navigate: '/education/sostavlyaem-portfel',
            },
            {
                id: 5,
                name: 'Фондовый рынок: основы',
                navigate: '/education/fondovij-rinok',
            },
        ],
    },
    expert: {
        title2: 'Опытным',
        exprertData: [
            {
                id: 6,
                name: 'Фондовый рынок: для опытных',
                navigate: '/education/obligatsii',
            },
            {
                id: 7,
                name: 'Личные финансы',
                navigate: '/education/lichnie-finansi',
            },
            { id: 8, name: 'Облигации', navigate: '/education/obligatsii-18' },
            {
                id: 9,
                name: 'Основы инвестиций',
                navigate: '/education/osnovi-investitsij',
            },
            {
                id: 10,
                name: 'Составляем портфель',
                navigate: '/education/sostavlyaem-portfel',
            },
        ],
    },
    advanced: {
        title3: 'Продвинутым',
        advancedData: [
            {
                id: 11,
                name: 'Фондовый рынок: профи',
                navigate: '/education/fondovij-rinok-profi',
            },
            {
                id: 12,
                name: 'Личные финансы',
                navigate: '/education/lichnie-finansi',
            },
            { id: 13, name: 'Облигации', navigate: '/education/obligatsii-18' },
            {
                id: 14,
                name: 'Основы инвестиций',
                navigate: '/education/osnovi-investitsij',
            },
            {
                id: 15,
                name: 'Составляем портфель',
                navigate: '/education/sostavlyaem-portfel',
            },
        ],
    },
};

export const portfolio = [
    {
        id: 1,
        name: 'Ideas РФ',
        navigate: 'ideas-russia',
        ymHref: 'Ideas РФ',
    },
    {
        id: 2,
        name: 'Ideas World',
        navigate: 'ideas-world',
        ymHref: 'Ideas World',
    },
    {
        id: 3,
        name: 'Unity РФ',
        navigate: 'unity-russia',
        ymHref: 'Unity РФ',
    },
    {
        id: 4,
        name: 'Unity World',
        navigate: 'unity-world',
        ymHref: 'Unity World',
    },
];

export const profile = [
    {
        id: 1,
        name: 'Личный кабинет',
        navigate: 'profile',
    },
    {
        id: 2,
        name: 'Вебинары',
        navigate: 'webinars',
    },
];

export const products = [
    {
        id: 82730517984533,
        name: 'Все продукты',
        navigate: 'products',
    },
    {
        id: 21344657687,
        name: 'Ideas РФ',
        navigate: 'products/ideas-russia',
    },
    {
        id: 134657678957,
        name: 'Ideas World',
        navigate: 'products/ideas-world',
    },
    {
        id: 13462654768,
        name: 'Ideas All',
        navigate: 'products/ideas-all',
    },
    {
        id: 7465467457,
        name: 'Unity РФ',
        navigate: 'products/unity-russia',
    },
    {
        id: 2347486957756,
        name: 'Unity World',
        navigate: 'products/unity-world',
    },
    {
        id: 3473272756586,
        name: 'Unity All',
        navigate: 'products/unity-all',
    },
    {
        id: 56384358665874,
        name: 'Premium',
        navigate: 'products/premium',
    },
];
