import { FC } from 'react';
import { Drawer, DrawerProps } from 'antd';
import clsx from 'classnames';
import './bottom-sheet.scss';
import Close from '@images/close.svg';
import { IeIcon } from '@components/ui/ie-icon/ie-icon';

type BottomSheetProps = DrawerProps;

export const BottomSheet: FC<BottomSheetProps> = ({ className, rootClassName, ...props }) => {
    return (
        <Drawer
            className={clsx({
                'bottom-sheet': true,
                [String(className)]: className,
            })}
            rootClassName={clsx({
                'bottom-sheet-root': true,
                [String(rootClassName)]: rootClassName,
            })}
            closeIcon={<IeIcon className="bottom-sheet-close" alt="" src={Close} />}
            placement="bottom"
            {...props}
        ></Drawer>
    );
};
