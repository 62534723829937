import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ICompanyComparisonDataResponse, CompanyComparisonType } from 'types/companies-compare.types';
import { CompanyToCompareType } from '@pages/companies-compare/companies-compare';
import { mainColumns } from '@pages/companies-compare/columns';
import { financialColumns } from '@pages/companies-compare/financialColumns';
import { CustomColumnsType } from '@pages/companies-compare/types';
import { LOCALSTORAGE_KEY_CELL_SIZE, LOCALSTORAGE_KEY_FONT_SIZE } from 'consts/companies-compare';

export type TabsType = 'main' | 'financial';
export type SizeType = 'small' | 'medium' | 'big';

export interface ICompaniesCompareState {
    data: CompanyComparisonType[];
    companiesToCompare: CompanyToCompareType[];
    isLoading: boolean;
    error: string | null;
    activeTab: TabsType;
    hiddenColumns: string[];
    cellSize: SizeType;
    fontSize: SizeType;
    columns: CustomColumnsType<CompanyComparisonType>;
}

const initialState: ICompaniesCompareState = {
    data: [],
    isLoading: false,
    error: null,
    activeTab: 'main',
    companiesToCompare: [],
    hiddenColumns: [],
    cellSize: (() => {
        const cellSize = localStorage.getItem(LOCALSTORAGE_KEY_CELL_SIZE);
        if (['medium', 'small', 'big'].includes(String(cellSize))) {
            return cellSize as SizeType;
        } else {
            return 'medium';
        }
    })(),
    fontSize: (() => {
        const fontSize = localStorage.getItem(LOCALSTORAGE_KEY_FONT_SIZE);
        if (['medium', 'small', 'big'].includes(String(fontSize))) {
            return fontSize as SizeType;
        } else {
            return 'medium';
        }
    })(),
    columns: mainColumns,
};

export const fetchCompanyComparison = createAsyncThunk<
    ICompanyComparisonDataResponse,
    { tickers: string[]; signal: AbortSignal }
>('companiesCompare/fetchCompanyComparison', async ({ tickers, signal }) => {
    const tickerNames = tickers.join('%2C');
    const response = await axios.get(`/api/companies/company-comparison/?ticker_names=${tickerNames}`, {
        signal,
    });
    return response.data;
});

const companiesCompareSlice = createSlice({
    name: 'companiesCompare',
    initialState,
    reducers: {
        setFetchedCompanies: (state, action: PayloadAction<CompanyComparisonType[]>) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        setActiveTab: (state, action: PayloadAction<TabsType>) => {
            state.activeTab = action.payload;
            if (state.activeTab === 'main') {
                state.columns = mainColumns as any;
            } else {
                state.columns = financialColumns as any;
            }
        },
        setCompaniesToCompare: (state, action: PayloadAction<CompanyToCompareType[]>) => {
            state.companiesToCompare = action.payload;
        },
        removeHiddenColumn: (state, action: PayloadAction<string>) => {
            state.hiddenColumns = state.hiddenColumns.filter((column) => column !== action.payload);
        },
        addHiddenColumn: (state, action: PayloadAction<string>) => {
            state.hiddenColumns.push(action.payload);
        },
        setCellSize: (state, action: PayloadAction<SizeType>) => {
            state.cellSize = action.payload;
        },
        setFontSize: (state, action: PayloadAction<SizeType>) => {
            state.fontSize = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyComparison.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(
                fetchCompanyComparison.fulfilled,
                (state, action: PayloadAction<ICompanyComparisonDataResponse>) => {
                    state.isLoading = false;
                    state.data = transformCompanyData(action.payload);
                }
            )
            .addCase(fetchCompanyComparison.rejected, (state, action) => {
                if (action.error.name !== 'CanceledError') {
                    state.isLoading = false;
                    state.error = action.error.message || 'Failed to fetch company comparison data';
                }
            });
    },
});

function transformCompanyData(data: ICompanyComparisonDataResponse): CompanyComparisonType[] {
    return Object.entries(data).map(([ticker, details]) => ({
        ticker,
        ...details,
    }));
}

export const {
    setFetchedCompanies,
    setActiveTab,
    setCompaniesToCompare,
    setCellSize,
    setFontSize,
    addHiddenColumn,
    removeHiddenColumn,
} = companiesCompareSlice.actions;
export default companiesCompareSlice.reducer;
