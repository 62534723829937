import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { setStatusTimer } from '@services/reducers/timer';
import { getTimeRemaining, getZero } from '@utils/timer';
import { ymHit } from '@utils/ym-hit.js';
// import StocksGift from '@images/stocksGift.png';
// import MobileGift from '@images/mobileGift.png';
// import StocksText from '@images/stocksText.png';
// import MobileStocksText from '@images/mobileStocksText.png';
import Close from '@images/close-black-icon.svg';
import { useResize } from '@services/hooks/use-resize';
import styles from './stocks-all-page.module.css';

const StockAllPages = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openFlag, setOpenFlag] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [allTime, setAllTime] = useState(0);
    const [daysTimer, setDaysTimer] = useState(0);
    const [hoursTimer, setHoursTimer] = useState(0);
    const [minutesTimer, setMinutesTimer] = useState(0);
    const [secondsTimer, setSecondsTimer] = useState(0);
    const [timeDifference, setTimeDifference] = useState(false);
    const dayTitles = ['день', 'дня', 'дней'];
    const daysText = `${daysTimer}  ${getDeclension(daysTimer, dayTitles)}`;
    const isMobile = useMediaQuery({ query: '(max-width: 1080px)' });
    const screenWidth = useResize();

    function getDeclension(number, titles) {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
    }

    const closeModal = (isMobile) => {
        if (isMobile) setMobileOpen(false);
        if (!isMobile) setOpen(false);
        setOpenFlag(true);
        localStorage.setItem('lastVisitDate', new Date().toLocaleDateString());
    };

    const onClickHandler = () => {
        ymHit('yellow-banner-timer-102024');
        setMobileOpen(false);
        setOpenFlag(true);
        localStorage.setItem('lastVisitDate', new Date().toLocaleDateString());
    };

    useEffect(() => {
        const targetDate = new Date('2024-12-11T15:00:00');

        const checkTimeDifference = () => {
            const currentDate = new Date();

            if (currentDate >= targetDate) {
                setTimeDifference(true);
                dispatch(setStatusTimer(true));
                clearInterval(dateInterval);
            }
        };

        const dateInterval = setInterval(checkTimeDifference, 0); // Проверка каждую секунду

        return () => clearInterval(dateInterval); // Очистка интервала при размонтировании компонента
    }, [dispatch]);

    useEffect(() => {
        const lastVisit = localStorage.getItem('lastVisitDate');
        const today = new Date().toLocaleDateString();
        let interval;

        if (lastVisit !== today && timeDifference) {
            interval = setInterval(() => {
                const timer = getTimeRemaining('2024-12-18T19:00:00');
                setAllTime(timer.total);
                setDaysTimer(timer.days);
                setHoursTimer(getZero(timer.hours));
                setMinutesTimer(getZero(timer.minutes));
                setSecondsTimer(getZero(timer.seconds));
            }, 0);
        } else {
            clearInterval();
        }

        if (allTime > 0 && lastVisit !== today) {
            setOpen(true);
        }

        if (allTime < 0 || lastVisit === today) {
            setOpen(false);
            setMobileOpen(false);
            setTimeDifference(false);
            dispatch(setStatusTimer(false));
            clearInterval(interval);
        }

        if (openFlag) {
            setOpen(false);
            setMobileOpen(false);
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [allTime, dispatch, openFlag, timeDifference]);

    useEffect(() => {
        let timer;
        if (isMobile && timeDifference) {
            timer = setTimeout(() => {
                setMobileOpen(true);
            }, 0);
        }

        return () => clearTimeout(timer);
    }, [isMobile, timeDifference]);

    return !isMobile ? (
        <div className={`${styles.stocksAllPage} ${open === false ? styles.stocksAllPageVisible : ''}`}>
            <div className={styles.wrapper}>
                <div className={styles.textImgBlock}>
                    {/*<img src={StocksText} alt="gift" loading="lazy" />*/}
                    <div className={styles.gift}>Стратегический вебинар:</div>
                    <div className={styles.giftTime}>Что будет с рынком при ставке в 25%?</div>
                </div>
                <div className={styles.monthStocks}>
                    {/*<div className={styles.gift}>До 2 месяцев в подарок</div>*/}
                    {/*<div className={styles.giftTime}>При покупке подписки от полугода</div>*/}
                    <div className={styles.gift}>Для регистрации укажите почту на сайте.</div>
                    <div className={styles.giftTime}>Зарегистрированным пользователям ссылка придёт автоматически.</div>
                </div>
                <div className={styles.stocksImg}>{/*<img src={StocksGift} alt="gift" loading="lazy" />*/}</div>
                <div className={styles.stocksTimer}>
                    {daysText} {screenWidth <= 1330 && <br />} {hoursTimer}:{minutesTimer}:{secondsTimer}
                </div>
                {/*<Link to={'/subscriptions'} className={styles.btn} onClick={onClickHandler}>*/}
                {/*    Приобрести подписку*/}
                <Link to={'/authorization'} className={styles.btn} onClick={onClickHandler}>
                    Зарегистрироваться
                </Link>
                <div onClick={() => closeModal(false)} className={styles.close}>
                    <img src={Close} alt="close" loading="lazy" />
                </div>
            </div>
        </div>
    ) : (
        <div className={`${styles.stocksAllPage} ${mobileOpen === false ? styles.stocksAllPageVisible : ''}`}>
            <div className={styles.textImgBlock}>
                {/*<img src={MobileStocksText} alt="gift" width={200} loading="lazy" />*/}
            </div>
            <div className={styles.wrapper}>
                <div onClick={() => closeModal(true)} className={styles.close}>
                    <img src={Close} alt="close" loading="lazy" />
                </div>
                <div className={styles.monthStocks}>
                    {/*<div className={styles.gift}>До 2 месяцев в подарок</div>*/}
                    {/*<div className={styles.giftTime}>При покупке подписки от полугода</div>*/}
                    <div className={styles.gift}>
                        <div className={styles.giftTime}>Стратегический вебинар:</div>
                        Что будет с рынком при ставке в 25%?
                    </div>
                </div>

                <div className={styles.stocksTimer}>
                    {daysText} {hoursTimer}:{minutesTimer}:{secondsTimer}
                </div>
                <div className={styles.textImgBlock}>
                    {/*<img src={StocksText} alt="gift" loading="lazy" />*/}

                    <div className={styles.describe}>
                        Для регистрации укажите почту на сайте. Зарегистрированным пользователям ссылка придёт
                        автоматически.
                    </div>
                </div>
                <div className={styles.stocksImg}>
                    {/*<Link onClick={onClickHandler} to={'/subscriptions'} className={styles.btn}>*/}
                    {/*    Приобрести подписку*/}
                    <Link to={'/authorization'} className={styles.btn} onClick={onClickHandler}>
                        Зарегистрироваться
                    </Link>
                    {/*<img src={MobileGift} alt="gift" className={styles.stocksImgGift} loading="lazy" />*/}
                </div>
            </div>
        </div>
    );
};

export default StockAllPages;
