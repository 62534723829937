import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { IScreenerDoc } from '../../types/screener/screener-docs.types';

interface IScreenerDocsState {
    data: IScreenerDoc[] | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: IScreenerDocsState = {
    data: null,
    isLoading: true,
    error: null,
};

export const fetchDocsData = createAsyncThunk<IScreenerDoc[], { ticker: string }, { rejectValue: string }>(
    'screenerDocs/fetchScreenerDocs',
    async ({ ticker }, { rejectWithValue }) => {
        try {
            const response = await axios(`/api/reports/get-report/?report_scale=&report_type=&ticker=${ticker}`);

            const data: IScreenerDoc[] = response.data;
            return data;
        } catch (error: any) {
            if (error.response.status === 404) {
                return rejectWithValue('Документы по данной компании отсутствуют');
            } else {
                return rejectWithValue('Непредвиденная ошибка. Пожалуйста, попробуйте позже');
            }
        }
    }
);

const screenerDividendsSlice = createSlice({
    name: 'screenerDocs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDocsData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchDocsData.fulfilled, (state, action: PayloadAction<IScreenerDoc[]>) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchDocsData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            });
    },
});

export default screenerDividendsSlice.reducer;
