import { ChangeEventHandler, FC, useMemo, useState } from 'react';
import { useAppSelector } from '@services/hooks/redux-hooks';
import { IRussianCompany } from '../../../types/screener/russian-companies.types';
import styles from '@pages/companies-compare/header.module.scss';
import Loop from '@images/loop.svg';
import { CompanyToCompareType } from '@pages/companies-compare/companies-compare';
import clsx from 'classnames';

type AddCompaniesProps = {
    setCompaniesToCompare: (tickersToCompare: CompanyToCompareType[]) => void;
    companiesToCompare: CompanyToCompareType[];
};

export const AddCompanies: FC<AddCompaniesProps> = ({ setCompaniesToCompare, companiesToCompare }) => {
    const { data: companies, isLoading } = useAppSelector((state) => state.russianCompanies);

    const [searchTerm, setSearchTerm] = useState('');

    const companiesBySector = useMemo(() => {
        if (!companies || companies.length === 0) return {};

        return companies.reduce(
            (acc, company) => {
                const sectorName = company.sector[0]?.name || 'Не указан';
                if (!acc[sectorName]) {
                    acc[sectorName] = [];
                }
                acc[sectorName].push(company);
                return acc;
            },
            {} as Record<string, typeof companies>
        );
    }, [companies, companiesToCompare]);

    const filteredCompaniesBySector = useMemo(() => {
        const searchTermLowercase = searchTerm.toLowerCase();

        return Object.entries(companiesBySector).reduce(
            (acc, [sector, sectorCompanies]) => {
                const filteredSectorCompanies = sectorCompanies.filter((company) => {
                    const name = company.name.toLowerCase();
                    const ticker = company.ticker.toLowerCase();
                    return name.includes(searchTermLowercase) || ticker.includes(searchTermLowercase);
                });

                if (filteredSectorCompanies.length > 0) {
                    acc[sector] = filteredSectorCompanies;
                }

                return acc;
            },
            {} as Record<string, IRussianCompany[]>
        );
    }, [searchTerm, companiesBySector]);

    if (isLoading) {
        return <div className={styles.loader}>Загрузка компаний...</div>;
    }

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleCompanyClick = (company: IRussianCompany) => {
        if (companiesToCompare.find((c) => c.ticker === company.ticker)) {
            setCompaniesToCompare(companiesToCompare.filter((c) => c.ticker !== company.ticker));
        } else {
            setCompaniesToCompare([...companiesToCompare, company]);
        }
    };

    return (
        <div
            className={clsx({
                [styles.addCompanies]: true,
                [styles.disabled]: companiesToCompare.length >= 10,
            })}
        >
            <label className={styles.searchField}>
                <img className={styles.loop} src={Loop} alt="" width={20} />
                <input type="text" placeholder="Поиск" value={searchTerm} onChange={handleChange} />
            </label>
            <div className={styles.companiesList}>
                {Object.entries(filteredCompaniesBySector).map(([sector, sectorCompanies]) => (
                    <div key={sector} className={styles.sectorGroup}>
                        <div className={styles.sectorName}>{sector}</div>
                        <div className={styles.companiesInSector}>
                            {sectorCompanies.map((company) => (
                                <button
                                    key={company.ticker}
                                    className={clsx({
                                        [styles.companyItem]: true,
                                        [styles.selected]: companiesToCompare.find((c) => c.ticker === company.ticker),
                                    })}
                                    onClick={() => handleCompanyClick(company)}
                                >
                                    {company.name}
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
