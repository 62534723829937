import { lazy } from 'react';

//lazyNamed используется для компонетов, которые экспортируются без default

export function lazyNamed<T extends { [key: string]: any }, K extends keyof T>(importFn: () => Promise<T>, key: K) {
    return lazy(() =>
        importFn().then((module) => ({
            default: module[key],
        }))
    );
}
