import { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import { EducationProvider } from '@services/context/education-context.jsx';
import AntdThemeProvider from './antd-theme-provider/antd-theme-provider';
import AppHeaderMain from './layout-main/app-header-main/app-header-main';
import FooterMain from './layout-main/footer-main/footer-main';
import { CompaniesCompare } from '@pages/companies-compare/companies-compare';

import { isClickEnter, isUserDelete } from '@services/reducers/modal-slice';
import { ymHit } from '@utils/ym-hit.js';
import {
    AboutCard,
    AboutUs,
    AnalyticsAndNews,
    AppMain,
    AuthorizationPage,
    BlogForLink,
    BlogPage,
    Education,
    Error403,
    Error500,
    Glossary,
    LayoutMain,
    Lesson,
    LessonsPage,
    MarketMap,
    NoAccess,
    PageForAddInfo,
    PageInfo,
    PageInfoUrl,
    ProductSub,
    Profile,
    TableNew,
    Tables,
    SubscriptionsPage,
    Webinars,
    ActiveButtonProvider,
    ActiveFiltersProvider,
    ActiveCountryTagProvider,
    InvestIdeas,
    PageInfoProvider,
    PopUpTimeoutAnalitycs,
    PopUpTimeoutEmail,
    PopUpTimeOutTables,
    PreloaderMain,
    ProfileProvider,
    WebinarProvider,
    ExternalRedirect,
    ScreenerDivider,
    ProductsEcosystem,
    Contacts,
} from './lazy-loaded-pages';
import styles from './app.module.css';

function App() {
    const dispatch = useDispatch();
    const location = useLocation();
    const isAuthChecked = useSelector((store) => store.user.isAuthChecked);
    const [showTimeoutPopupFirst, setShowTimeoutPopupFirst] = useState(false);
    const [showTimeoutPopupSecond, setShowTimeoutPopupSecond] = useState(false);
    const [showTimeoutPopupThird, setShowTimeoutPopupThird] = useState(false);

    useEffect(() => {
        ymHit(location.pathname + location.search, 'hit');
    }, [location.pathname]);

    useEffect(() => {
        if (location.pathname !== '/authorization') {
            dispatch(isUserDelete(false));
            dispatch(isClickEnter(false));
        }
        if (
            localStorage.getItem('token') &&
            location.pathname !== '/authorization' &&
            location.pathname !== '/profile' &&
            location.pathname !== '/not-found'
        ) {
            localStorage.setItem('location', location.pathname);
        }
    }, [location.pathname]);

    useEffect(() => {
        let timer;

        // Проверка на поискового бота
        const userAgent = navigator.userAgent.toLowerCase();
        const isSearchBot =
            userAgent.includes('yandex') || userAgent.includes('google') || userAgent.includes('yadirectfetcher');

        if (isSearchBot) return; // Если это поисковый бот, ничего не делаем
        if (localStorage.getItem('noPopUp')) return;
        if (isAuthChecked) return;

        if (!isAuthChecked && location.pathname !== '/authorization' && location.pathname !== '/profile') {
            const firstVisit = localStorage.getItem('firstVisit');
            const secondVisit = localStorage.getItem('secondVisit');
            const thirdVisit = localStorage.getItem('thirdVisit');

            if (!firstVisit && !secondVisit && !thirdVisit) {
                timer = setTimeout(() => {
                    setShowTimeoutPopupFirst(true);
                    localStorage.setItem('firstVisit', 'firstVisit');
                }, 15000);
            } else if (firstVisit && !secondVisit && !thirdVisit) {
                timer = setTimeout(() => {
                    setShowTimeoutPopupSecond(true);
                    localStorage.setItem('secondVisit', 'secondVisit');
                }, 15000);
            } else if (firstVisit && secondVisit && !thirdVisit) {
                timer = setTimeout(() => {
                    setShowTimeoutPopupThird(true);
                    localStorage.setItem('thirdVisit', 'thirdVisit');
                }, 15000);
            }
            if (thirdVisit) {
                setShowTimeoutPopupFirst(false);
                setShowTimeoutPopupSecond(false);
                setShowTimeoutPopupThird(false);
            }
        }

        return () => clearTimeout(timer);
    }, [isAuthChecked, location.pathname]);

    useEffect(() => {
        if (showTimeoutPopupFirst) {
            ymHit('popup-our-analysis');
        }
        if (showTimeoutPopupSecond) {
            ymHit('popup-promo-start');
        }
        if (showTimeoutPopupThird) {
            ymHit('popup-email');
        }
    }, [showTimeoutPopupFirst, showTimeoutPopupSecond, showTimeoutPopupThird]);

    useEffect(() => {
        if (showTimeoutPopupFirst || showTimeoutPopupSecond || showTimeoutPopupThird) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [showTimeoutPopupFirst, showTimeoutPopupSecond, showTimeoutPopupThird]);

    return (
        <HelmetProvider>
            <AntdThemeProvider>
                {showTimeoutPopupFirst && <PopUpTimeoutAnalitycs setShowTimeoutPopupFirst={setShowTimeoutPopupFirst} />}
                {showTimeoutPopupSecond && <PopUpTimeOutTables setShowTimeoutPopupSecond={setShowTimeoutPopupSecond} />}
                <ActiveFiltersProvider>
                    {showTimeoutPopupThird && <PopUpTimeoutEmail setShowTimeoutPopupThird={setShowTimeoutPopupThird} />}
                </ActiveFiltersProvider>
                <ActiveCountryTagProvider className={styles.app}>
                    <AppHeaderMain />
                    <Suspense fallback={<PreloaderMain />}>
                        <Routes>
                            <Route path="/" element={<LayoutMain />}>
                                <Route path="/authorization" element={<AuthorizationPage />} />

                                <Route element={<WebinarProvider />}>
                                    <Route path="/webinars" element={<Webinars />} />
                                </Route>

                                <Route element={<ActiveFiltersProvider />}>
                                    <Route path="/analytics-and-news/" element={<AnalyticsAndNews />} />
                                    <Route path="/analytics-and-news/:slug" element={<AboutCard />} />
                                    <Route path="/about-us" element={<AboutUs />} />
                                </Route>

                                <Route element={<EducationProvider />}>
                                    <Route path="/education" element={<Education />} />
                                    <Route path="/education/:slug" element={<LessonsPage />} />
                                    <Route path="/lessons/:slug" element={<Lesson />} />
                                </Route>

                                <Route element={<ProfileProvider />}>
                                    <Route index element={<AppMain />} />
                                    <Route path="/profile" element={<Profile />} />
                                    <Route path="/products" element={<ProductsEcosystem />} />
                                    <Route path="/products/:slug" element={<ProductSub />} />
                                    <Route path="/subscriptions" element={<SubscriptionsPage />} />
                                </Route>

                                <Route element={<ActiveButtonProvider />}>
                                    <Route path="/tables/:slug" element={<Tables />} />
                                    <Route path="/tables/:subs/:sub/:slug" element={<TableNew />} />
                                    <Route path="/polezno-investoru/" element={<BlogPage />} />
                                    <Route path="/polezno-investoru/:slug" element={<BlogForLink />} />
                                </Route>

                                <Route element={<PageInfoProvider />}>
                                    <Route path="/info" element={<PageInfo />} />
                                    <Route path="/info/:slug" element={<PageInfoUrl />} />
                                </Route>

                                <Route path="/contacts" element={<Contacts />} />
                                <Route path="/market-map" element={<MarketMap />} />
                                <Route path="/glossary" element={<Glossary />} />
                                <Route path="/screener" element={<Navigate to="/screener/all" replace />} />
                                <Route path="/screener/:slug" element={<ScreenerDivider />} />
                                <Route path="/invest-ideas" element={<InvestIdeas />} />
                                <Route path="/companies-compare" element={<CompaniesCompare />} />

                                <Route path="/nv342390n7n903n90mv39082380v23v48" element={<PageForAddInfo />} />

                                <Route path="/noaccess" element={<NoAccess />} />
                                <Route path="/error-500" element={<Error500 />} />
                                <Route path="/error-403" element={<Error403 />} />
                                <Route path="*" element={<ExternalRedirect />} />
                            </Route>
                        </Routes>
                    </Suspense>
                    <FooterMain />
                </ActiveCountryTagProvider>
            </AntdThemeProvider>
        </HelmetProvider>
    );
}

export default App;
