import { FC } from 'react';
import { CompanyToCompareType } from '@pages/companies-compare/companies-compare';
import styles from './header.module.scss';

type RemoveCompaniesProps = {
    companiesToCompare: CompanyToCompareType[];
    setCompaniesToCompare: (companies: CompanyToCompareType[]) => void;
};

export const RemoveCompanies: FC<RemoveCompaniesProps> = ({ companiesToCompare, setCompaniesToCompare }) => {
    const removeCompany = (company: CompanyToCompareType) => {
        setCompaniesToCompare(companiesToCompare.filter((c) => c !== company));
    };

    return (
        <div className={styles.removeCompaniesList}>
            {companiesToCompare.map((company) => (
                <button
                    className={styles.removeCompanyitem}
                    key={company.ticker}
                    onClick={() => removeCompany(company)}
                >
                    {company.name}
                </button>
            ))}
            {companiesToCompare.length === 0 && <p className={styles.noRemoveCompaniesData}>Компании не выбраны</p>}
        </div>
    );
};
