import { ColumnsType } from 'antd/es/table';
import { CompanyComparisonType } from '../../../types/companies-compare.types';

export const mainColumns: ColumnsType<CompanyComparisonType> = [
    {
        title: 'Название компании',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
    },
    {
        title: 'Тикер',
        dataIndex: 'ticker',
        key: 'ticker',
    },
    {
        title: 'Сектор',
        dataIndex: 'sector',
        key: 'sector',
    },
    {
        title: 'Капитализация (млрд ₽)',
        dataIndex: 'market_cap',
        key: 'market_cap',
    },
    {
        title: 'EV TTM (млрд ₽)',
        dataIndex: 'ev_ttm',
        key: 'ev_ttm',
    },
    {
        title: 'Чистый долг TTM (млрд ₽)',
        dataIndex: 'net_debt_ttm',
        key: 'net_debt_ttm',
    },
    {
        title: 'Динамика котировок за 1 месяц (%)',
        dataIndex: '1_month_quote_dynamics',
        key: '1_month_quote_dynamics',
        render: (value: number) => `${value}%`,
    },
    {
        title: 'Динамика котировок за 3 месяца (%)',
        dataIndex: '3_months_quote_dynamics',
        key: '3_months_quote_dynamics',
        render: (value: number) => `${value}%`,
    },
    {
        title: 'Динамика котировок за 1 год (%)',
        dataIndex: '1_year_quote_dynamics',
        key: '1_year_quote_dynamics',
        render: (value: number) => `${value}%`,
    },
    {
        title: 'Див. доходность текущая (%)',
        dataIndex: 'dividend_yield_current',
        key: 'dividend_yield_current',
        render: (value: string | null) => (value ? `${value}%` : '—'),
    },
    {
        title: 'Див. доходность 2024 (%)',
        dataIndex: 'dividend_yield_2024',
        key: 'dividend_yield_2024',
        render: (value: string | null) => (value ? `${value}%` : '—'),
    },
];
