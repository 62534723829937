import clsx from 'classnames';
import styles from './button.module.scss';
import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    disabled?: boolean;
    className?: string;
    variant?: 'primary' | 'secondary' | 'text' | 'outlined';
    size?: 'large' | 'small';
}

export const Button: FC<ButtonProps> = ({
    children,
    disabled = false,
    onClick,
    className,
    variant = 'primary',
    size = 'large',
    ...rest
}) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={clsx(styles.button, className, {
                [styles.primary]: variant === 'primary',
                [styles.text]: variant === 'text',
                [styles.outlined]: variant === 'outlined',
                [styles.secondary]: variant === 'secondary',
                [styles.small]: size === 'small',
                [styles.large]: size === 'large',
            })}
            {...rest}
        >
            {children}
        </button>
    );
};
