import { FC, LiHTMLAttributes } from 'react';
import styles from './header-link.module.scss';
import { NavLink, NavLinkProps } from 'react-router-dom';
import clsx from 'classnames';

type HeaderLinkPropsType = {
    className?: string;
    linkClassName?: string;
    to: string;
    children: React.ReactNode;
    navLinkProps?: NavLinkProps;
} & LiHTMLAttributes<HTMLLIElement>;

export const HeaderLink: FC<HeaderLinkPropsType> = ({
    className,
    linkClassName,
    to,
    children,
    navLinkProps,
    ...otherProps
}) => {
    return (
        <li className={clsx(styles.listItem, className)} {...otherProps}>
            <NavLink className={clsx(styles.link, linkClassName)} to={to} {...navLinkProps}>
                {children}
            </NavLink>
        </li>
    );
};
