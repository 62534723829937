import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './reducers/modal-slice';
import userReducer from './reducers/user-slice';
import singleCardReducer from './reducers/single-card-slice';
import preloaderReducer from './reducers/preloader-slice';
import cardsReducer from './reducers/cards-slice';
import cardsFiltredReduser from './reducers/cards-filtred-slice';
import cardsFilteredForGalleryReducer from './reducers/cards-filtred-for-gallery-slice';
import cardsFiltredForCheckboxPointsSlice from './reducers/cards-filtred-for-checkbox-points-slice';
import cardsMainNewReduser from './reducers/cards-main-new-slice';
import userDataReduser from './reducers/subscription-data-slice';
import cardsMainAnalyticsReduser from './reducers/cards-main-analytics-slice';
import buttonDisabledReducer from './reducers/button-disabled-slice';
import errorReducer from './reducers/error-slice';
import checkboxesReducer from './reducers/checkboxes-slice';
import inputTextReducer from './reducers/input-text-slice';
import openedCheckboxesReducer from './reducers/opened-checkboxes-slice';
import pagesReducer from './reducers/pages-slice';
import selectedItemReducer from './reducers/selectedItem-slice';
import recommendedPostsReducer from '../services/reducers/recommended-posts-slice';
import countDataReducer from './reducers/count-data-slice';
import valueDataFilterReducer from './reducers/value-data-filter-slice';
import nextUrlReducer from './reducers/next-url-slice';
import tablesReducer from './reducers/tables-slice';
import searchSectionVisionReducer from './reducers/search-vision-points-slice';
import countryInfo from './reducers/info-country/country-info';
import timerSlice from './reducers/timer';
import { screenerReducer } from './reducers/screener-slice';
import { blogReducer } from './reducers/blog-slice';
import { shareListReducer } from './reducers/share-list-slice';
import screenerDividendsSlice from './reducers/screener-dividends-slice';
import russianCompaniesSlice from './reducers/russian-companies-slice';
import investIdeasSlice from '@services/reducers/invest-ideas-slice';
import screenerDocsSlice from '@services/reducers/screener-docs-slice';
import companiesCompareSlice from '@services/reducers/companies-compare-slice';

export const store = configureStore({
    reducer: {
        modal: modalReducer,
        user: userReducer,
        singleCard: singleCardReducer,
        preloader: preloaderReducer,
        cards: cardsReducer,
        cardsMainNew: cardsMainNewReduser,
        userData: userDataReduser,
        cardsFiltred: cardsFiltredReduser,
        cardsFiltredForGallery: cardsFilteredForGalleryReducer,
        searchSectionVision: searchSectionVisionReducer,
        checkBoxPoints: cardsFiltredForCheckboxPointsSlice,
        cardsMainAnalytics: cardsMainAnalyticsReduser,
        buttonDisabled: buttonDisabledReducer,
        error: errorReducer,
        checkboxes: checkboxesReducer,
        inputText: inputTextReducer,
        openedCheckboxes: openedCheckboxesReducer,
        pages: pagesReducer,
        selectedItems: selectedItemReducer,
        recommendedPosts: recommendedPostsReducer,
        countData: countDataReducer,
        valueDataFilter: valueDataFilterReducer,
        nextUrl: nextUrlReducer,
        tables: tablesReducer,
        countryInfo: countryInfo,
        timerSlice: timerSlice,
        screener: screenerReducer,
        blog: blogReducer,
        shareList: shareListReducer,
        screenerDividends: screenerDividendsSlice,
        russianCompanies: russianCompaniesSlice,
        investIdeas: investIdeasSlice,
        screenerDocs: screenerDocsSlice,
        companiesCompare: companiesCompareSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
