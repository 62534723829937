import { Link } from 'react-router-dom';
import { ymHit } from '@utils/ym-hit.js';
import styles from './two-level-menu.module.css';

//выпадающая менюшка для "Обучение"
export const TwoLevelMenu = ({ open, data, closeModal }) => {
    return (
        <div className={`${styles.twoLevelMenu} ${open ? styles.open : ''}`}>
            <div className={styles.wrapper}>
                <div className={styles.wrapIetm}>
                    <h2 className={styles.title}>{data.begin.title1}</h2>
                    <ul className={styles.list}>
                        {data.begin.beginnerData.map((element) => (
                            <li onClick={closeModal} className={styles.item} key={element.id}>
                                <Link className={styles.link} to={element.navigate}>
                                    {element.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.wrapIetm}>
                    <h2 className={styles.title}>{data.expert.title2}</h2>
                    <ul className={styles.list}>
                        {data.expert.exprertData.map((element) => (
                            <li onClick={closeModal} className={styles.item} key={element.id}>
                                <Link className={styles.link} to={element.navigate}>
                                    {element.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.wrapIetm}>
                    <h2 className={styles.title}>{data.advanced.title3}</h2>
                    <ul className={styles.list}>
                        {data.advanced.advancedData.map((element) => (
                            <li onClick={closeModal} className={styles.item} key={element.id}>
                                <Link className={styles.link} to={element.navigate}>
                                    {element.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

//выпадающая менюшка для "Портфели"
export const TwoLevelMenuOther = ({ open, data, closeModal, clazz }) => {
    const onClickHandlerYM = (name) => {
        if (name === 'Ideas РФ') ymHit(`clck-hdr-tbls-ids_rus`);
        if (name === 'Ideas World') ymHit(`clck-hdr-tbls-ids_wrld`);
        if (name === 'Unity РФ') ymHit(`clck-hdr-tbls-unt_rus`);
        if (name === 'Unity World') ymHit(`clck-hdr-tbls-unt_wrld`);
    };

    return (
        <div
            style={{ background: '#fff' }}
            className={`${styles.twoLevelMenu1} ${open ? styles.open1 : ''} ${clazz ? styles.clazz : ''}`}
        >
            <ul className={styles.list1}>
                {data?.map((element) => (
                    <li
                        onClick={() => {
                            closeModal();
                            onClickHandlerYM(element.name);
                        }}
                        className={styles.title}
                        key={element.id}
                    >
                        <Link className={styles.link1} to={`/tables/${element.navigate}`}>
                            {element.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

//выпадающая менюшка для "Тарифы"
export const TwoLevelMenuSubscriptions = ({ open, data, closeModal }) => {
    const onClickHandlerYM = (name) => {
        if (name === 'Подписки') ymHit(`clck-hdr-trfs-drpdwn`);
        if (name === 'Ideas РФ') ymHit(`clck-hdr-trfs-ids_rus-drpdwn`);
        if (name === 'Ideas World') ymHit(`clck-hdr-trfs-ids_wrld-drpdwn`);
        if (name === 'Ideas All') ymHit(`clck-hdr-trfs-ids_all-drpdwn`);
        if (name === 'Unity РФ') ymHit(`clck-hdr-trfs-unt_rus-drpdwn`);
        if (name === 'Unity World') ymHit(`clck-hdr-trfs-unt_wrld-drpdwn`);
        if (name === 'Unity All') ymHit(`clck-hdr-trfs-unt_all-drpdwn`);
        if (name === 'Premium') ymHit(`clck-hdr-trfs-prm-drpdwn`);
    };

    return (
        <div
            style={{ background: '#fff' }}
            className={`${styles.twoLevelMenu1} ${styles.subscriptionsMenu} ${open ? styles.open1 : ''}`}
        >
            <ul className={`${styles.list1} ${styles.subscriptionsList}`}>
                {data?.map((element) => (
                    <li
                        onClick={() => {
                            closeModal();
                            onClickHandlerYM(element.name);
                        }}
                        className={styles.title}
                        key={element.id}
                    >
                        <Link className={styles.link1} to={`/${element.navigate}`}>
                            {element.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

//выпадающая менюшка для "Профиль"
export const TwoLevelMenuOtherProfile = ({ open, data, closeModal, clazz }) => {
    const onClickHandlerYM = (name) => {
        if (name === 'Личный кабинет') ymHit(`clck-hdr-prfl-cbnt`);
        if (name === 'Вебинары') ymHit(`clck-hdr-prfl-wbrns`);
    };
    return (
        <div
            style={{ background: '#fff' }}
            className={`${styles.twoLevelMenu1} ${styles.menuLocation} ${open ? styles.open1 : ''} ${
                clazz ? styles.clazz : ''
            }`}
        >
            <div className={styles.wrapper1}>
                <div className={styles.wrapIetm}>
                    <ul className={styles.list1}>
                        {data.map((element) => (
                            <li
                                onClick={() => {
                                    closeModal();
                                    onClickHandlerYM(element.name);
                                }}
                                className={styles.title}
                                key={element.id}
                            >
                                <Link className={styles.link1} to={`/${element.navigate}`}>
                                    {element.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
