import clsx from 'classnames';
import ArrowLeft2 from '../../../images/arrows/arrow-left-2.svg';
import { NavLink } from 'react-router-dom';
import styles from './breadcrumb-navigation.module.scss';
import { FC } from 'react';
import { IeIcon } from '@components/ui/ie-icon/ie-icon';

interface BreadcrumbLink {
    label: string;
    url: string;
}

interface BreadcrumbNavigationProps {
    links: BreadcrumbLink[];
    forBg?: 'light' | 'dark';
    className?: string;
    withMarginBottom?: boolean;
}

export const BreadcrumbNavigation: FC<BreadcrumbNavigationProps> = ({
    links,
    forBg = 'dark',
    className,
    withMarginBottom = true,
}) => {
    const linkClass = clsx(styles.link, {
        [styles.linkForDarkBg]: forBg === 'dark',
    });
    const wrapperClass = clsx(styles.navigationBox, className, {
        [styles.marginBottom]: withMarginBottom,
    });

    return (
        <div className={wrapperClass}>
            {links.map((link, index) => (
                <NavLink key={index} className={linkClass} to={link.url}>
                    <IeIcon alt="стрелка влево" src={ArrowLeft2} />
                    {link.label}
                </NavLink>
            ))}
        </div>
    );
};
