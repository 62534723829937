import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getShareList } from '@utils/api';
import { ICompany, IFetchShareListResponse, ISector } from 'types/screener/share-list.types';

interface ShareListState {
    sectors: ISector[];
    companies: ICompany[];
    loading: boolean;
    error: string | null;
    scrollTop: number;
    selectedSector: ISector;
    selectedCategory: { id: string; name: string };
}

// Асинхронный thunk для получения данных
export const fetchShareList = createAsyncThunk<IFetchShareListResponse, void, { rejectValue: string }>(
    'shareList/fetchShareList',
    async (_, { rejectWithValue }) => {
        try {
            const res = await getShareList();
            if (res.ok) {
                return await res.json();
            } else {
                return rejectWithValue('Error fetching data');
            }
        } catch (error: any) {
            return rejectWithValue(error.message || 'Unknown error');
        }
    }
);

// Начальное состояние
const initialState: ShareListState = {
    sectors: [],
    companies: [],
    loading: false,
    error: null,
    scrollTop: 0,
    selectedSector: { id: 0, name: 'Отрасль' },
    selectedCategory: { id: 'all', name: 'Все' },
};

const shareListSlice = createSlice({
    name: 'shareList',
    initialState,
    reducers: {
        setScrollTop: (state, action: PayloadAction<number>) => {
            state.scrollTop = action.payload;
        },
        setSelectedSector: (state, action: PayloadAction<ISector>) => {
            state.selectedSector = action.payload;
        },
        setSelectedCategory: (state, action: PayloadAction<{ id: string; name: string }>) => {
            state.selectedCategory = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchShareList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchShareList.fulfilled, (state, action: PayloadAction<IFetchShareListResponse>) => {
                state.loading = false;
                state.sectors = action.payload.sectors;
                state.companies = action.payload.companies;
            })
            .addCase(fetchShareList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch data';
            });
    },
});

export const shareListReducer = shareListSlice.reducer;
export const { setScrollTop, setSelectedCategory, setSelectedSector } = shareListSlice.actions;
